import * as ActionTypes from "../../constants/ActionTypes";

export const maintain = (payload) => {
  return {
    type: ActionTypes.MAINTAIN,
    payload: payload,
  };
};

export const offNoti = () => {
  return {
    type: ActionTypes.OFF_NOTIFICATION,
  };
};

export const apiLoadingStart = (payload) => {
  return {
    type: ActionTypes.ON_API_LOADING,
    payload: payload,
  };
};

export const apiLoadingStop = (payload) => {
  return {
    type: ActionTypes.OFF_API_LOADING,
    payload: payload,
  };
};

export const getRefreshTokenSuccess = (payload) => {
  return {
    type: ActionTypes.REFRESH_TOKEN_SUCCESS,
    payload: payload,
  };
};

export const profileFetchStart = () => {
  return {
    type: ActionTypes.PROFILE_FETCH_START,
  };
};

export const profileFetchSuccess = (profile) => {
  // console.log("action profileFetchSuccess", profile)
  return {
    type: ActionTypes.PROFILE_FETCH_SUCCESS,
    payload: profile,
  };
};

export const profileFetchError = (error) => {
  return {
    type: ActionTypes.PROFILE_FETCH_ERROR,
    payload: error,
  };
};
/////////////////////////////////////////////////////////////

export const listForkFetchStart = () => {
  return {
    type: ActionTypes.LISTFORK_FETCH_START,
  };
};

export const listForkFetchSuccess = (profile) => {
  // console.log("action listForkFetchSuccess", profile)
  return {
    type: ActionTypes.LISTFORK_FETCH_SUCCESS,
    payload: profile,
  };
};

export const listForkFetchError = (error) => {
  return {
    type: ActionTypes.LISTFORK_FETCH_ERROR,
    payload: error,
  };
};

/////////////////////////////////////////////////////////////

export const listAirdropFetchStart = (data) => {
  return {
    type: ActionTypes.LIST_AIRDROP_FETCH_START,
    payload: data,
  };
};

export const listAirdropFetchSuccess = (data) => {
  return {
    type: ActionTypes.LIST_AIRDROP_FETCH_SUCCESS,
    payload: data,
  };
};

export const listAirdropFetchError = (error) => {
  return {
    type: ActionTypes.LIST_AIRDROP_FETCH_ERROR,
    payload: error,
  };
};

/////////////////////////////////////////////////////////////

export const getEventsFetchStart = () => {
  return {
    type: ActionTypes.GET_EVENTS_FETCH_START,
  };
};

export const getEventsFetchSuccess = (data) => {
  return {
    type: ActionTypes.GET_EVENTS_FETCH_SUCCESS,
    payload: data,
  };
};

export const getEventsFetchError = (error) => {
  return {
    type: ActionTypes.GET_EVENTS_FETCH_ERROR,
    payload: error,
  };
};

///////////////////getallwallet//////////////////////////

export const getallwalletStart = () => {
  return {
    type: ActionTypes.GET_ALL_WALLET_START,
  };
};

export const getallwalletSuccess = (wallet) => {
  // console.log("action getallwalletSuccess", wallet)
  return {
    type: ActionTypes.GET_ALL_WALLET_SUCCESS,
    payload: wallet,
  };
};

export const getallwalletError = (error) => {
  return {
    type: ActionTypes.GET_ALL_WALLET_ERROR,
    payload: error,
  };
};

///////////////////getWalletForkInfo//////////////////////////

export const getWalletForkInfoStart = (forkId) => {
  return {
    type: ActionTypes.GET_WALLET_FOLK_INFO_START,
    payload: forkId,
  };
};

export const getWalletForkInfoSuccess = (wallet) => {
  // console.log("action getWalletForkInfoSuccess", wallet)
  return {
    type: ActionTypes.GET_WALLET_FOLK_INFO_SUCCESS,
    payload: wallet,
  };
};

export const getWalletForkInfoError = (error) => {
  return {
    type: ActionTypes.GET_WALLET_FOLK_INFO_ERROR,
    payload: error,
  };
};

///////////////////loveFork//////////////////////////

export const loveForkStart = (forkId) => {
  return {
    type: ActionTypes.LOVE_FORK_START,
    payload: forkId,
  };
};

export const loveForkSuccess = (data) => {
  return {
    type: ActionTypes.LOVE_FORK_SUCCESS,
    payload: data,
  };
};

export const loveForkError = (error) => {
  return {
    type: ActionTypes.LOVE_FORK_ERROR,
    payload: error,
  };
};

///////////////////requestAirdrop////////////////////

export const requestAirdropStart = (data) => {
  return {
    type: ActionTypes.REQUEST_AIRDROP_START,
    payload: data,
  };
};

export const requestAirdropSuccess = (data) => {
  return {
    type: ActionTypes.REQUEST_AIRDROP_SUCCESS,
    payload: data,
  };
};

export const requestAirdropError = (error) => {
  return {
    type: ActionTypes.REQUEST_AIRDROP_ERROR,
    payload: error,
  };
};

//////////////////////getMarkets//////////////////////

export const getMarketsStart = (data) => {
  return {
    type: ActionTypes.GET_MARKETS_START,
    payload: data,
  };
};

export const getMarketsSuccess = (data) => {
  return {
    type: ActionTypes.GET_MARKETS_SUCCESS,
    payload: data,
  };
};

export const getMarketsError = (error) => {
  return {
    type: ActionTypes.GET_MARKETS_ERROR,
    payload: error,
  };
};

export const marketsFilter = (data) => {
  return {
    type: ActionTypes.MARKETS_FILTER,
    payload: data,
  };
};

