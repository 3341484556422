import React, { useState } from "react";
import { Col, Row, Button, Modal } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory, Redirect } from "react-router-dom";
import ModalLogin from "../containers/ModalLogin";

const Page = (props) => {
  const authUser = useSelector(({ auth }) => auth.authUser);
  const history = useHistory();
  const [visible, setVisible] = useState(true)
  const [showLogin, setshowLogin] = useState(false);

  // console.log("(props.requireLogin  )  ", props.requireLogin)
  if (props.requireLogin) {
    if (authUser !== null)
      return (<Row>
        <Col span={24}>{props.children}</Col>
      </Row>)
    else {
      return (
        <Row>
          <Col span={24}>
            <div style={{ display: 'flex', flexDirection: 'column',justifyContent:'center', alignItems: 'center', paddingBottom: 24 }}>
              <p>Wellcome ! You need to login to experience this feature.</p>
              <Button style = {{marginBottom: 0, fontSize: 18, fontWeight: 700 }} type="primary" ghost onClick={() => setshowLogin(true)}>Click here to Login</Button>
            </div>
            <Modal
              visible={showLogin}
              title = {<strong style = {{display:'flex',alignSelf:'center',justifyContent:'center'}}>LOGIN</strong>}
              footer={null}
              style={{ top: 50 }}
              onCancel={() => {
                setshowLogin(false)
              }}
              onOk={() => {
                setshowLogin(false)
              }}
            > 
              <ModalLogin
                onDone={() => {
                  setshowLogin(false)
                }}></ModalLogin>
            </Modal>

          </Col>
        </Row>
      )
    }
  }
  else {
    return (<Row>
      <Col span={24}>{props.children}</Col>
    </Row>)
  }


};

export default Page;
