import React, { useEffect } from "react";
import { Button, Checkbox, Form, Input, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { GoogleLogin } from 'react-google-login';

import {
  hideMessage,
  showAuthLoader,
  userFacebookSignIn,
  userGoogleSignIn,
  userSignIn,

  getProfileInfo,
  userGoogleSignInSuccess,
  profileFetchStart
} from "../appRedux/actions";

import IntlMessages from "util/IntlMessages";
import CircularProgress from "../components/CircularProgress";
import FacebookOutlined from "@ant-design/icons/lib/icons/FacebookOutlined";
import GoogleOutlined from "@ant-design/icons/lib/icons/GoogleOutlined";

const ModalLogin = ({ onDone }) => {
  const dispatch = useDispatch();
  const { loader, alertMessage, showMessage, authUser } = useSelector(
    ({ auth }) => auth
  );
  const history = useHistory();

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 100);
    }
    if (authUser !== null) {
      // console.log(authUser)
      history.push("/");
    }
  });

  const onFinishFailed = (errorInfo) => { };
  // const loginBackEnd = () => {

  // }
  const onFinish = (values) => {
    // dispatch(showAuthLoader());
    dispatch(userSignIn(values));
    // dispatch(userSignInTest(values));
  };
  const onGoogleSuccess = (response) => {
    // console.log(response);
    onDone()
    const loginData = {
      tokenID: response.tokenId,
      loginType: "GOO"
    }
    dispatch(userSignIn(loginData))
    // dispatch(profileFetchStart())

  }
  const onGoogleFail = (response) => {
    // console.log(response);
  }
  return (
    <div style={{ display: 'inline-flex', flexWrap: "wrap", flexDirection: 'row', justifyContent: 'space-around', maxWidth: 600, alignItems: 'center' }}>
      {/* <div className="gx-app-login-container"> */}
      <div>
        <img alt="example" style={{ maxWidth: 160, height: "auto", display: "block", marginLeft: "auto", marginRight: "auto" }} src="https://res.cloudinary.com/chianow-jsc/image/upload/v1630491782/chianow-logo-text.png" />
      </div >
      <div style={{ display: 'inline-flex', flexDirection: 'column', justifyContent: 'space-around', maxWidth: 300, alignItems: 'center', marginLeft: 10 }}>
        <p>
          Welcome to the world of seeds! Here, the forked seeds from Chia-Network can be bought, sold, exchanged, used to participate in many entertaining games...and a lot of interesting things are waiting for you. Join us!!!
        </p>
        <GoogleLogin
          // style={{ backgroundColor: "#84A459", boxShadow: "0px 4px 4px 1px rgba(164, 157, 157, 0.25)", borderRadius: "15px" }}
          theme="dark"
          clientId="735713249947-dttasjh1li39ma6t5qahkv684a883gds.apps.googleusercontent.com"
          buttonText="Login with google"
          onSuccess={onGoogleSuccess}
          onFailure={onGoogleFail}
          cookiePolicy={'single_host_origin'}
        />
      </div>

      {loader ? (
        <div className="gx-loader-view">
          <CircularProgress />
        </div>
      ) : null}
      {showMessage ? message.error(alertMessage.toString()) : null}
    </div>
    // </div>
    // </div>
  );
};

export default ModalLogin;
