import './icons/cursor.css'
import React, { useState, useEffect,} from "react";
import Widget from "../../components/Widget/index";
import { useSelector, useDispatch } from "react-redux";
import { getMarketsStart } from "../../appRedux/actions/Api"
import { unix_to_short_date } from "../../util/Tools"
import { Table, Tooltip, Typography, Space, Checkbox, Modal, BackTop,Slider } from "antd";
import { delorder, confirmOrder } from "../../appRedux/sagas/Api";
import { NotificationManager} from "react-notifications";
import { getWalletForkInfoStart } from "appRedux/actions/Api";
import { startCounter,pauseCounter, } from "../../appRedux/actions/Common";
import { nu } from "../../util/Tools";

import SelectCoin from './SelectCoin';
import NumericInput from "../components/NumericInput"

const MarketTable = () => {
  const dispatch = useDispatch();
  const { loading, profile, listMartket, listFork } = useSelector(({ api }) => api)
  const [amount, setAmount] = useState(null)
  const [price, setPrice] = useState(null)
  const [forkForm, setForkForm] = useState(null)
  const [forkTo, setForkTo] = useState(null)
  const [myOrder, setMyOrder] = useState(false)
  const [marketFilter, setMarketFilter] = useState(null)
  const [showConfirm, setShowConfirm] = useState(false)
  const [marketIdConfirm, setMarketIdConfirm] = useState(null)
  const [minConfirm, setMinConfirm] = useState(null)
  const [maxConfirm, setMaxConfirm] = useState(null)
  const [ffConfirm, setffConfirm] = useState(null)
  const [ttConfirm, setttConfirm] = useState(null)
  const [priceConfirm, setPriceConfirm] = useState(null)

  // var s = parseInt((Date.parse(listEvent.stopTime) - Date.now()) / 1000)
  // useEffect(() => {
  //   if (listEvent.stopTime !== undefined) {
  //     dispatch(startCounter(s))
  //   }
  // }, [listEvent.stopTime, secs])


  const forkSwapRender = (forkId, forkIdSwap) => {
    if (listFork === undefined || listFork.length === 0) return null
    let forkSymbol = listFork.find(e => e.forkId === forkId).symbol
    let forkSwapSymbol = listFork.find(e => e.forkId === forkIdSwap).symbol
    return (<li>{`sell ${forkSymbol} / buy ${forkSwapSymbol}`}</li>)
  }
  
  const ConfirmTransaction = (props) => {
    const { form, to, min, max, price, show, onOk, onCancel } = props
    let f = (listFork.find(r => r.forkId === form))
    let t = (listFork.find(r => r.forkId === to))
    let ff = f//.length === 1 ? undefined : f
    let tt = t//.length === 1 ? undefined : t
    const marks = {
      0: min.toFixed(5),
      50: ((min + max) / 2).toFixed(5),
      100: (max).toFixed(5),
    };
    const [acf, setacf] = useState(max + '')
    const [slideValue, setSlideValue] = useState(100)
    return (
      <Modal title={<strong>Confirm Transaction</strong>} visible={show} width={420} closable={false} //confirmLoading
        destroyOnClose maskClosable={false}
        onOk={() => { onOk(acf) }}
        onCancel={() => { onCancel() }}>
        <space>
          <li> Please select an amount from the range below and confirm this transaction before we do it. </li>
          <p></p>
          <li> ◦ You buy <b className="gx-text-blue"> {ff.symbol} </b> / sell <b className="gx-text-blue"> {tt.symbol}</b> </li>
          <li style={{ marginTop: 5 }}> <BackTop /> ◦  Price: <strong className="gx-text-blue"> {`1 ${ff.symbol} ~ ${nu(8, price)} ${tt.symbol}`} </strong> <BackTop /></li>
          <li style={{ marginTop: 5 }}> <BackTop /> ◦  Trade: <strong className="gx-text-blue"> {`In ${nu(8, acf)} ${ff.symbol} / Out ${nu(8, acf * price)} ${tt.symbol}`} </strong> <BackTop /></li>
          <li style={{ marginTop: 5 }}> <BackTop /> ◦  Fee(2%): <strong className="gx-text-blue"> {`${nu(8, acf*0.02)} ${ff.symbol}`} </strong> </li>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 5 }}>
            <li style={{ marginRight: 33 }}> <BackTop /> ◦  Amount: </li>
            <NumericInput autoFocus value={acf} size="large" style={{ width: 180, fontSize: 17, fontWeight: 800 }}
              onFocus={e => e.target.select()}
              onChange={value => {
                if (value !== '') {
                  // if (min <= value * 1 && value * 1 <= max) {
                    setacf(value)
                    setSlideValue((100 * (value - min) / (max - min)).toFixed(0))
                  // }
                  // else if (min > value * 1) {
                  //   setacf(min)
                  //   setSlideValue(0)
                  // } else if (max < value * 1) {
                  //   setacf(max)
                  //   setSlideValue(100)
                  // }
                } else {
                  setacf('')
                  setSlideValue(0)
                }
              }}
            />
          </div>
          <div style={{ marginLeft: 20, marginRight: 20 }}>
            <Slider
              tipFormatter={value => `${(min + (max - min) * value / 100).toFixed(5)}`}
              marks={marks}
              step={1}
              min={0} max={100}
              value={slideValue}
              onChange={(value) => {
                setSlideValue(value)
                setacf((min + (max - min) * value / 100).toFixed(5))
              }}
            />
          </div>
        </space>
      </Modal>
    )
  }
 
  const columnsMarket = [
    { title: "ID", dataIndex: "marketId" },
    {
      title: "Acc", render: (item) => {
        return (
          <Tooltip placement="top" title={item.accName}>
            <Typography.Text ellipsis={true} style={{ width: 80 }}>
              {item.accName}
            </Typography.Text>
          </Tooltip>
        )
      },
    },
    {
      title: "SWAP", width: 150, render: (item) => forkSwapRender(item.forkId, item.forkSwapId)
    },
    {
      title: "MinAmount", render: (item) => {
        return (
          <li>{`${nu(6,item.minAmountSwap * item.amountSwap)}`}</li>
        )
      },
    },
    {
      title: "MaxAmount", render: (item) => {
        return (
          <li>{`${nu(6,item.amountSwap)}`}</li>
        )
      },
    },
    {
      title: "Price", render: (item) => {
        return (
          <li>{`${nu(8,item.priceSwap)}`}</li>
        )
      },
    },
    {
      title: "Priority", width: 60, render: (item) => {
        return (
          <li>{`${parseFloat(item.priority * 100).toFixed(2)}%`}</li>
        )
      },
    },
    {
      title: "EndTime", width: 145, render: (item) => {
        return (
          <li>{`${unix_to_short_date(new Date(item.stopTime))}`}</li>
        )
      },
    },
    {
      title: "Action", width: 90, render: (item) => {
        if (!profile || profile === undefined) { return null }
        else {
          return (
            profile.accID !== item.accID ?
              <div className='hover-button-market' style={{
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: '#a6cf6f',
                paddingTop: 5,
                paddingBottom: 5,
                borderRadius: 10,
              }}
                onClick={() => {
                  dispatch(pauseCounter())
                  setMarketIdConfirm(item.marketId)
                  setMinConfirm(item.amountSwap * item.minAmountSwap)
                  setMaxConfirm(item.amountSwap)
                  setPriceConfirm(item.priceSwap)
                  setffConfirm(item.forkId)
                  setttConfirm(item.forkSwapId)
                  setShowConfirm(true)
                }}
              >
                <strong> Confirm </strong>
              </div>
              :
              <div className='hover-button-market' style={{
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: 'tomato',
                paddingTop: 5,
                paddingBottom: 5,
                borderRadius: 10,
              }}
                onClick={() => {
                  delorder({ marketId: item.marketId }, () => {
                    dispatch(getWalletForkInfoStart({ forkId: item.forkId }));
                    dispatch(getMarketsStart())
                    NotificationManager.success('Your order has been deleted, we have refunded you XCH, check your wallet', 'Success');
                  })
                }}
              >
                <strong style={{ color: 'white' }}> Cancel </strong>
              </div>
          )
        }
      },
    },
  ];
  
  const filter = (myOrder, forkToId, forkFormId, price, amount) => {
    let filterMK = listMartket.filter(e => {
      return (e.forkSwapId === forkToId || forkToId === 0 || !forkToId) &&
        (e.forkId === forkFormId || forkFormId === 0 || !forkFormId) &&
        (e.priceSwap <= price || !price || price === 0) &&
        ((e.amountSwap * e.minAmountSwap <= amount && amount <= e.amountSwap) || !amount || amount === 0) &&
        ((myOrder && profile && e.accID === profile.accID) || !myOrder || !profile)
    })
    setMarketFilter(filterMK)
  }

  useEffect(() => { filter(myOrder, forkTo ? forkTo.forkId : undefined, forkForm ? forkForm.forkId : undefined, price, amount) }, [listMartket])
  
  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(getMarketsStart())
    }, 1000 * 60);
    return () => clearInterval(interval);
  }, [])

  
  return (
    <div>
      <Widget title={<strong style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}> P2P-Market </strong>} >
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Space style={{ display: 'flex', alignItems: 'center' }} >
            <Checkbox style={{ fontWeight: 700 }}
              onChange={value => {
                setMyOrder(value.target.checked);
                filter(value.target.checked, forkTo ? forkTo.forkId : undefined, forkForm ? forkForm.forkId : undefined, price, amount)
              }}> MY ORDER</Checkbox>
            <hr width="1" size="500"></hr>
            <strong>Sell: </strong>
            <SelectCoin all
              value={{ value: forkForm ? forkForm.forkId : 0 }}
              disableForkId={forkTo ? forkTo.forkId : undefined}
              onChange={el => {
                var item = listFork.find(r => r.forkId === el.value)
                setForkForm(item)
                filter(myOrder, forkTo ? forkTo.forkId : undefined, el.value, price, amount)
              }}
            />
            <strong>Buy: </strong>
            <SelectCoin all
              value={{ value: forkTo ? forkTo.forkId : 0 }}
              disableForkId={forkForm ? forkForm.forkId : undefined}
              onChange={el => {
                var item = listFork.find(r => r.forkId === el.value)
                setForkTo(item)
                filter(myOrder, el.value, forkForm ? forkForm.forkId : undefined, price, amount)
              }}
            />
            <li />
            <li />
            <strong>Amount: </strong>
            <NumericInput value={amount} size="large" style={{ width: 140, fontSize: 17, fontWeight: 800 }}
              onChange={value => {
                setAmount(value)
                filter(myOrder, forkTo ? forkTo.forkId : undefined, forkForm ? forkForm.forkId : undefined, price, value)
              }}
            />
            <strong>Price: </strong>
            <NumericInput value={price} size="large" style={{ width: 140, fontSize: 17, fontWeight: 800 }}
              onChange={value => {
                setPrice(value)
                filter(myOrder, forkTo ? forkTo.forkId : undefined, forkForm ? forkForm.forkId : undefined, value, amount)
              }}
            />
          </Space>
        
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <div className='hover-button-market' style={{
              backgroundColor: '#ececec',
              padding: 7,
              borderRadius: 10,
            }} onClick={() => {
              dispatch(getMarketsStart())
            }
            }>
              <strong style={{ color: '#595959' }}> Refresh ! </strong>
            </div>
          </div>
        </div>
            
        <hr />
        <Table
          loading={loading[getMarketsStart().type]}
          className="gx-table-no-bordered"
          pagination={{ position: 'bottomRight' }}
          columns={columnsMarket}
          dataSource={marketFilter}
          bordered={false}
          size="middle"
        />
      </Widget>
      {
        marketIdConfirm && <ConfirmTransaction
          form={ffConfirm}
          to={ttConfirm}
          min={minConfirm}
          max={maxConfirm}
          price={priceConfirm}
          show={showConfirm}
          onOk={(amount) => {
            // console.log(amount)
            confirmOrder({
              marketId: marketIdConfirm,
              amount: amount
            }, () => {
              setMarketIdConfirm(null)
              setShowConfirm(false)
              dispatch(getWalletForkInfoStart({ forkId: ffConfirm }));
              dispatch(getWalletForkInfoStart({ forkId: ttConfirm }));
              dispatch(getMarketsStart())
              NotificationManager.success('Transaction has been completed successfully, please check your wallet', 'Success');
            }, error => {
              setMarketIdConfirm(null)
              NotificationManager.warning(error.message,'Warning');
            })
            setShowConfirm(false)
          }}
          onCancel={() => { setShowConfirm(false) }}
        />
      }
    </div>
  );
};
export default MarketTable;
