import { eventChannel, END } from 'redux-saga';
import { call, fork, put, take, cancel, cancelled } from 'redux-saga/effects';
import {
  counterStarted,
  counterPaused,
  updateCounter,
  countOver
} from '../actions/Common';
import {
  START_COUNTER,
  PAUSE_COUNTER,
  COUNT_OVER,
} from '../../constants/ActionTypes';

const countdown = secs =>
  eventChannel(emit => {
    const counter = setInterval(() => {
      secs -= 1;
      emit(secs >= 0 ? secs : END);
    }, 1000);

    return () => clearInterval(counter);
  });

function* countdownTask(secs) {
  const chan = yield call(countdown, secs);

  try {
    while (true) {
      const sec = yield take(chan);
      yield put(updateCounter(sec));
    }
  } finally {
    if (yield cancelled()) {
      chan.close();
    } else {
      yield put(countOver());
    }
  }
}

function* watchCountdown() {
  while (true) {
    const { payload: { secs } } = yield take(START_COUNTER);

    const task = yield fork(countdownTask, secs);
    yield put(counterStarted());

    const { type } = yield take([PAUSE_COUNTER, COUNT_OVER]);
    if (type === PAUSE_COUNTER) {
      yield cancel(task);
      yield put(counterPaused());
    }
  }
}

export default watchCountdown;






















// import { take, call } from 'redux-saga/effects'
// import { eventChannel, END } from 'redux-saga'

// function countdown(secs) {
//   return eventChannel(emitter => {
//       const iv = setInterval(() => {
//         secs -= 1
//         if (secs > 0) { emitter(secs) } else { emitter(END) }
//       }, 1000);
//       return () => {
//         clearInterval(iv)
//       }
//     }
//   )
// }

// export default function* countdownSaga(value) {
//   const chan = yield call(countdown, value)
//   try {    
//     while (true) {
//       // take(END) will cause the saga to terminate by jumping to the finally block
//       let seconds = yield take(chan)
//       console.log(`countdown: ${seconds}`)
//     }
//   } finally {
//     console.log('countdown terminated')
//   }
// }
