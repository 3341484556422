import * as ActionTypes from "../../constants/ActionTypes";

export const wsConnect = (token) => {
    return {
        type: ActionTypes.WS_CONNECT,
        token: token
    }
};

export const wsReConnect = (token) => {
    return {
        type: ActionTypes.WS_RESCONNECT,
        token: token
    }
};