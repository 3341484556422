import Icon from '@ant-design/icons';
import './arrow.css'

const swap = () => (
    // <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 1024 1024">
    //     <path d="m512 256c0 141.386719-114.613281 256-256 256s-256-114.613281-256-256 114.613281-256 256-256 256 114.613281 256 256zm0 0" fill="#48c8ef"/>
    //     <path d="m480 256c0 123.710938-100.289062 224-224 224s-224-100.289062-224-224 100.289062-224 224-224 224 100.289062 224 224zm0 0" fill="#e4edfb"/>
    //     <path d="m108.128906 195.871094c6.246094 6.246094 16.375 6.246094 22.625 0l29.246094-29.246094v105.136719c0 8.835937 7.164062 16 16 16s16-7.164063 16-16v-105.136719l29.246094 29.246094c6.25 6.246094 16.378906 6.246094 22.625 0s6.246094-16.375 0-22.625l-67.871094-67.871094-67.871094 67.871094c-6.246094 6.25-6.246094 16.378906 0 22.625zm0 0" fill="#48c8ef"/>
    //     <path d="m381.246094 316-29.246094 29.246094v-105.117188c0-8.835937-7.164062-16-16-16s-16 7.164063-16 16v105.117188l-29.246094-29.246094c-6.25-6.246094-16.378906-6.246094-22.625 0s-6.246094 16.375 0 22.625l67.871094 67.871094 67.871094-67.871094c6.246094-6.25 6.246094-16.378906 0-22.625s-16.375-6.246094-22.625 0zm0 0" fill="#48c8ef"/>
    //         <g fill="#24b2d3">
    //             <path d="m160 319.761719h32v32h-32zm0 0"/>
    //             <path d="m160 383.761719h32v32h-32zm0 0"/>
    //             <path d="m320 160.128906h32v32h-32zm0 0"/>
    //             <path d="m320 96.128906h32v32h-32zm0 0"/>
    //         </g>
    // </svg>
    <svg width="1em" height="1em" viewBox="0 0 1024 1024">
        <linearGradient id="a" gradientTransform="matrix(1 0 0 -1 0 -13210)" gradientUnits="userSpaceOnUse" x1="0" x2="512" y1="-13466" y2="-13466">
            <stop offset="0" stop-color="#31d8ff"/>
            <stop offset="1" stop-color="#ff80ff"/>
        </linearGradient>
        <path d="m512 256c0 141.386719-114.613281 256-256 256s-256-114.613281-256-256 114.613281-256 256-256 256 114.613281 256 256zm0 0" fill="url(#a)"/>
        <path d="m348.105469 348.449219c-5.871094-5.84375-15.367188-5.820313-21.210938.050781l-55.894531 56.164062v-333.664062c0-8.285156-6.714844-15-15-15s-15 6.714844-15 15v333.664062l-55.894531-56.164062c-5.84375-5.871094-15.339844-5.894531-21.210938-.050781-5.871093 5.84375-5.894531 15.339843-.050781 21.210937l81.523438 81.917969c5.800781 5.800781 15.464843 5.800781 21.265624 0l81.523438-81.917969c5.84375-5.871094 5.824219-15.367187-.050781-21.210937-5.871094-5.84375 5.875 5.84375 0 0zm0 0" fill="#fff"/>
    </svg>
);

const SwapIcon = props => <Icon component={swap} {...props} />;

const Arrow = props => {
  return (
    <div {...props} className="arrow">
        <span></span>
        <span></span>
        <span></span>
    </div>
  );
}
export {
    SwapIcon,Arrow
};