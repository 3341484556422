import { take, put, call, takeEvery, race } from 'redux-saga/effects'
import { eventChannel } from 'redux-saga'
import { NotificationManager } from "react-notifications";

import md5 from 'md5'
import {
    MAINTAIN,
    REFRESH_TOKEN_SUCCESS,
    SIGNOUT_USER_SUCCESS,
    WS_BALANCE,
    WS_CONNECT,
    WS_LOVE_FORK,
    WS_NOTIFICATION
} from '../../constants/ActionTypes'
// import { createWebSocketConnection } from './socketConnection'

// const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0cCI6ImFjY2VzcyIsImFjY0lEIjoiY25vdzEzYzl6amMyNWtrczF5OTg5MmdvbyIsImlhdCI6MTYyODY2MzAzNCwiZXhwIjoxNjMxMjU1MDM0fQ.E3vcid-jGDZu7q62ElcXhgVBRsCT5MRHe0x2FBQQ2NE"

const wsUrl = 'wss://backend.chianow.net:1313/acc'
let countRestart = 5
function initWebsocket(innitToken) {
    // console.log(token)

    let token = innitToken
    return eventChannel(emitter => {

        const ws = new WebSocket(wsUrl, ["accessToken", token])
        ws.onopen = () => {
            // console.log('opening socket...', ws)
            countRestart = 5
            // ws.send('hello server')
        }
        // ws.onerror = (error) => {
        //     console.log('WebSocket error ' + error)
        //     console.dir(error)
        // }

        ws.onmessage = (e) => {
            let msg = null
            try {
                // console.log(e.data)
                msg = JSON.parse(e.data)
                // console.log(msg)
            } catch (e) {
                // console.error(`Error parsing : ${e.data}`)
            }
            if (msg) {
                // {"cmd":"balance","data":{"accID":"cnow1hkum9tsvksg9t542goo","balance":{"5":680000}},"sign":"b3328e8de673387b5091e1b411565595"}
                // console.log(msg)
                const { cmd, data, sign } = msg
                const checkMD5 = md5(cmd + '|' + JSON.stringify(data) + '|' + 'PgOqWmAxUgI2FOWW93ugYBv70MKYo6r7')
                
                // console.log(checkMD5)
                // console.log(sign)
                // console.log(cmd)

                if (checkMD5 === sign)
                    switch (cmd) {
                        case 'balance':
                            NotificationManager.success('Coin Income...','Amazing, good job!');
                            return emitter({ type: WS_BALANCE, payload: data })
                        case 'lovefork':
                            return emitter({ type: WS_LOVE_FORK, payload: data })  //{forkId: 10, loveCnt: 3} 
                        case 'maintain':
                            return emitter({ type: MAINTAIN, payload: data })
                        case 'notification':
                            return emitter({ type: WS_NOTIFICATION, payload: data })
                        default:
                        // nothing to do
                    }
                else { console.warn("messese not allow-----------------") }
            }
        }
        ws.onclose = function (e) {
            if (countRestart > 0) {
                // console.log('Socket is closed. Reconnect socket...', e.reason, countRestart);
                countRestart = countRestart - 1;
                setTimeout(function () {
                    initWebsocket(token);
                }, 5000);
            }
        };

        ws.onerror = function (err) {
            console.error('Socket encountered error: ', err.message, 'Closing socket');
            ws.close();
        };

        // unsubscribe function
        return (_token) => {

            // if (_token) {
            //     token = _token
            //     countRestart = 5
            // }
            // else {
            //     countRestart = 0
            // }
            countRestart = 0
            // console.log('Socket off')
            ws.close();

        }
    })
}
function* connectWebSocket({ token }) {
    try {
        let channel = yield call(initWebsocket, token)
        while (true) {
            const { logoutAction, refreshToken, socketAction } = yield race({
                logoutAction: take(SIGNOUT_USER_SUCCESS),
                refreshToken: take(REFRESH_TOKEN_SUCCESS),
                socketAction: take(channel)
            });

            if (logoutAction) {
                channel.close();
            }
            else if (refreshToken) {
                channel.close();
                channel = yield call(initWebsocket, refreshToken.payload)
            }
            else {
                // console.log(socketAction)
                yield put(socketAction)
            }
        }
    } catch (error) {
        // console.log(error)
    }
}
export default function* wsSagas() {
    yield takeEvery(WS_CONNECT, connectWebSocket);
}
