import React, { useEffect, useState } from "react";
import { Modal, Col } from "antd";
import WidgetCountDown from "./WidgetCountDown";
import TableAirdrop from "./TableAirdrop";
import GetAirDrop from "./GetAirDrop";
import { useSelector, useDispatch } from "react-redux";

const AirdropList = (props) => {
  const {
    listEvent,
    eventForkInfo,
  } = useSelector(({ api }) => api);

  const [visible, setVisible] = useState(false);
  
  useEffect(() => {
  
  }, [])

  return (
    <div className="gx-price-tables gx-pt-circle">
      {(listEvent.state === 'COMINGSOON') ?
        <Col xl={20} lg={24} md={20} xs={24}>
          <WidgetCountDown
            styleName="gx-package gx-bg-white gx-highlight gx-border-1"
            headerStyle="gx-package-header gx-bg-warning gx-text-white"
            itemStyle="gx-package-body gx-text-dark"
            footerStyle="gx-btn-block gx-btn-primary"
            onClick={() => { }}
          />
        </Col>
        :
        <TableAirdrop onClick={() => { setVisible(true) }} />
      }
      <Modal
        visible={visible}
        title={<strong style={{ display: 'flex', alignSelf: 'center', justifyContent: 'center' }}>REQUEST AIRDROP</strong>}
        footer={null}
        onCancel={() => { setVisible(false) }}
        onOk={() => { setVisible(false) }}
      >
        <GetAirDrop
          loveDairy={eventForkInfo.loveDairy}
          style={{ paddingTop: 20 }}
          eventId={listEvent.eventId}
          forks={eventForkInfo.forks}
          wait={eventForkInfo.wait}
        ></GetAirDrop>
      </Modal>
    </div>
  )
};
export default AirdropList;

