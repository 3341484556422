import { all, call, fork, put, takeEvery,takeLatest } from "redux-saga/effects";
import axios from "axios";
import * as ApiCall from "../../constants/ApiServer";
import { notification, Button } from 'antd';
import { NotificationManager } from "react-notifications";
import {
  PROFILE_FETCH_START,
  LIST_AIRDROP_FETCH_START,
  LISTFORK_FETCH_START,
  LISTFORK_FETCH_SUCCESS
  // GET_ALL_WALLET_START
} from "constants/ActionTypes";
import * as action from "../../appRedux/actions/Api";


import { message } from "antd";
import { GET_WALLET_FOLK_INFO_START, GET_EVENTS_FETCH_START, GET_EVENTS_FETCH_SUCCESS, GET_MARKETS_START, SIGNOUT_USER_SUCCESS } from "../../constants/ActionTypes";
import { userSignOut } from "../actions";
import store from "../store";


function requestToken(refreshToken) {
  // console.log("refreshToken start")
  // const refreshToken = localStorage.getItem('refreshToken');
  const refreshTokenURL = ApiCall.authApi.refreshToken;
  return axios.post(refreshTokenURL, { refreshToken: refreshToken })
}

function* getf5TokenFailure() {
  yield call(userSignOut)
}

function handleCommonError(error, onError) {
  // console.log(error)
  if (error.response && error.response.data) {
    // console.log(error.response)
    const data = error.response.data

    if (error.response.status === 401) {
      const token = localStorage.getItem('token');
      const refreshToken = localStorage.getItem('refreshToken');
      if (token === null || refreshToken === null) {
        message.info("You need login to use this feature!");
        store.dispatch(userSignOut())
        return;
      }
      requestToken(refreshToken).then((response) => {
        // console.log(response)
        const data = response.data
        if (data && data.success) {
          localStorage.setItem("token", data.accessToken);
          error.config.headers.Authorization = `Bearer ${data.accessToken}`
          // console.log("recall failed with config", error.config)
          // axios.request(error.config)
          store.dispatch(action.getRefreshTokenSuccess(data.accessToken))
        }

      }).catch(function (error) {
        message.error("Your token has expired,Please login again!");
        store.dispatch(userSignOut())
      });
    }
    else {
      NotificationManager.error('System Error!');
    }
  }
  else {
    NotificationManager.error('System Error!');
  }
}

const getProfileRequest = () => {
  return axios.get(ApiCall.getProfileApi, ApiCall.getConfigGetWithToken())
    .then(function (response) {
      // console.log(response)
      return response
    })
    .catch(function (error) {
      return { error: error }
    });
}

function* getProfile() {
  try {
    const response = yield call(
      getProfileRequest
    );
    if (response.error) {
      handleCommonError(response.error)
    } else {
      // console.log("getProfile ok", response.data)
      localStorage.setItem("profile", JSON.stringify(response.data.profile));
      yield put(action.profileFetchSuccess(response.data.profile));
    }
  } catch (error) {
    yield put(action.profileFetchError(error));
  }
}

const getEventRequest = () => {
  return axios.get(ApiCall.getEvents)
    .then(function (response) {
      // console.log(response)
      return response
    })
    .catch(function (error) {
      return { error: error }
    });
}

function* getEvents() {
  try {
    // yield put(action.apiLoadingStart(GET_EVENTS_FETCH_START));
    const response = yield call(getEventRequest);
    if (response.error) {
      handleCommonError(response.error)
    } else {
      // fork(yield takeEvery(LISTFORK_FETCH_SUCCESS, function* run() {
        yield put(action.getEventsFetchSuccess(response.data));
        yield put(action.listAirdropFetchStart(response.data[0].eventId)); //TODO: sử  lý lại với nhiều event cung lúc
      // }));
    }
  } catch (error) {
    yield put(action.getEventsFetchError(error));
  }
}

const getAirdropRequest = (eventId) => {
  return axios.get(ApiCall.getListAirdrop + '?eventId=' + eventId)
    .then(function (response) {
      return response
    })
    .catch(function (error) {
      return { error: error }
    });
}

function* getListAirdrop({ payload }) {
  try {
    const response = yield call(getAirdropRequest, payload);
    if (response.error) {
      handleCommonError(response.error)
      yield put(action.listAirdropFetchError(response.error));
    } else {

      yield put(action.listAirdropFetchSuccess(response.data.data));
    }
  } catch (error) {

  }
}

const getListForkRequest = () => {
  return axios.get(ApiCall.getListFork)
    .then(function (response) {
      // console.log(response)
      return response
    })
    .catch(function (error) {
      return { error: error }
    });
}

function* getListFork() {
  try {
    // yield put(action.apiLoadingStart());
    const response = yield call(getListForkRequest);
    if (response.error) {
      // yield put(showAuthMessage(signInUser.message));
      handleCommonError(response.error)
      yield put(action.listForkFetchError(response.error))
    } else {
      yield put(action.listForkFetchSuccess(response.data));
    }
  } catch (error) {
  }
}

const getWalletForkInfoRequest = (payload) => {
  return axios.get(ApiCall.getWalletForkInfo, {
    ...ApiCall.getConfigGetWithToken(),
    params: payload,
  })
    .then(function (response) {
      // console.log("raw respone", response)
      return response
    })
    .catch(function (error) {
      return { error: error }
    });
}

function* getWalletForkInfo({ payload }) {
  try {
    const response = yield call(getWalletForkInfoRequest, payload);
    // console.log("getWalletForkInfo reponse", response)
    if (response.error) {
      handleCommonError(response.error, getf5TokenFailure)
      yield put(action.getWalletForkInfoError(response.error));
    } else {
      yield put(action.getWalletForkInfoSuccess(response.data));
    }
  } catch (error) {
    yield put(action.getWalletForkInfoError(error));
  }
}


const getMarketsRequest = (payload) => {
  return axios.get(ApiCall.getMarkets)
    .then(function (response) {
      return response
    })
    .catch(function (error) {
      return { error: error }
    });
}

function* getMarkets({ payload }) {
  try {
    yield put(action.apiLoadingStart(GET_MARKETS_START));
    const response = yield call(getMarketsRequest, payload);
    if (response.error) {
      // yield put(showAuthMessage(signInUser.message));
      handleCommonError(response.error)
      yield put(action.apiLoadingStop(GET_MARKETS_START));
      yield put(action.getMarketsError(response.error))
    } else {
      yield put(action.apiLoadingStop(GET_MARKETS_START));
      yield put(action.getMarketsSuccess(response.data));
    }
  } catch (error) {
    yield put(action.getMarketsError(error))
  }
}

export function* getApi() {
  yield takeEvery(PROFILE_FETCH_START, getProfile);
  yield takeEvery(GET_WALLET_FOLK_INFO_START, getWalletForkInfo);
  yield takeEvery(LISTFORK_FETCH_START, getListFork);
  yield takeEvery(LISTFORK_FETCH_SUCCESS, getEvents);
  yield takeEvery(GET_EVENTS_FETCH_START, getEvents);  
  yield takeEvery(GET_EVENTS_FETCH_SUCCESS, getListAirdrop);  
  yield takeEvery(LIST_AIRDROP_FETCH_START, getListAirdrop);
  yield takeEvery(GET_MARKETS_START, getMarkets);
}


export default function* rootSaga() {
  yield all([
    fork(getApi)
  ]);
}

/////NO SAGA ------------------------------------------------
export const initAddress = (payload, onSuccess) => {
  return axios.post(ApiCall.initAddress, payload, ApiCall.getConfigGetWithToken())
    .then(function (response) {
      // console.log(response)
      if (response.data.success) {
        if (onSuccess) {
          onSuccess()
        }
        NotificationManager.success('Wallet initialization','Success');
      } else {
        if (response.error) {
          NotificationManager.error('System Error!');
        }
      }
      return response
    })
    .catch(function (error) {
      // handleCommonError(error, getf5TokenFailure)
    });;
}

export const requestAirdrop = (payload, onSuccess) => {
  return axios.post(ApiCall.requestAirdrop, payload, ApiCall.getConfigGetWithToken())
    .then(function (response) {
      // console.log(response)
      if (response.data.success) {
        if (onSuccess) {
          onSuccess()
        }
        NotificationManager.success('Airdrop request was successful, you will receive the coin in a few minutes','Success');
      } else {
        if (response.error) {
          NotificationManager.error('System Error!','Error');
        } else {
          NotificationManager.warning(response.data.message,'Warning');
        }
      }
      return response
    })
    .catch(function (error) {
      // handleCommonError(error, getf5TokenFailure)
    });;
}

export const loveFork = (payload, onSuccess) => {
  return axios.post(ApiCall.loveFork, payload, ApiCall.getConfigGetWithToken())
    .then(function (response) {
      // console.log(response)
      if (response.data.success) {
        if (onSuccess) { onSuccess() }
      } else {
        if (response.error) {
          NotificationManager.error('System Error!','Error');
        } else {
          NotificationManager.warning(response.data.message,'Warning');
        }
      }
      return response
    })
    .catch(function (error) {
      handleCommonError(error, getf5TokenFailure)
    });;
}

export const orderTrade = (payload, onSuccess, onError) => {
  return axios.post(ApiCall.orderTrade, payload, ApiCall.getConfigGetWithToken())
    .then(function (response) {
      // console.log(response)
      if (response.data.success) {
        if (onSuccess) { onSuccess(response.data) }
      } else {
        if (onError) { onError(response.data) }
        if (response.error) {
          NotificationManager.error('System Error!','Error');
        } else {
          NotificationManager.warning(response.data.message,'Warning');
        }
      }
      return response
    })
    .catch(function (error) {
      handleCommonError(error, getf5TokenFailure)
    });;
}

export const getWalletHistory = (payload, onSuccess) => {
  return axios.get(ApiCall.getWalletHistory,{
    ...ApiCall.getConfigGetWithToken(),
    params: payload,
  })
    .then(function (response) {
      // console.log(response)
      if (response.data.success) {
        if (onSuccess) { onSuccess(response.data.history) }
      } else {
        
      }
      return response
    })
    .catch(function (error) {
      handleCommonError(error, getf5TokenFailure)
    });;
}

export const delorder = (payload, onSuccess, onError) => {
  return axios.post(ApiCall.delOrder, payload, ApiCall.getConfigGetWithToken())
    .then(function (response) {
      if (response.data.success) {
        if (onSuccess) { onSuccess(response.data) }
      } else {
        if (onError) { onError(response.data) }
        if (response.error) {
          NotificationManager.error('System Error!','Error');
        } else {
          // NotificationManager.warning(response.data.message,'Warning');
        }
      }
      return response
    })
    .catch(function (error) {
      handleCommonError(error, getf5TokenFailure)
    });;
}

export const withDrawOTP = (payload, onSuccess, onError) => {
  return axios.post(ApiCall.withDrawOTP, payload, ApiCall.getConfigGetWithToken())
    .then(function (response) {
      if (response.data.success) {
        // console.log(response.data)
        if (onSuccess) { onSuccess(response.data) }
      } else {
        if (onError) { onError(response.data) }
        if (response.error) {
          NotificationManager.error('System Error!','Error');
        } else {
          NotificationManager.warning(response.data.message,'Warning');
        }
      }
      return response
    })
    .catch(function (error) {
      handleCommonError(error, getf5TokenFailure)
    });;
}

export const withDraw = (payload, onSuccess, onError) => {
  return axios.post(ApiCall.withDraw, payload, ApiCall.getConfigGetWithToken())
    .then(function (response) {
      if (response.data.success) {
        if (onSuccess) { onSuccess(response.data) }
      } else {
        if (onError) { onError(response.data) }
        if (response.error) {
          NotificationManager.error('System Error!','Error');
        } else {
          // NotificationManager.warning(response.data.message,'Warning');
        }
      }
      return response
    })
    .catch(function (error) {
      handleCommonError(error, getf5TokenFailure)
    });;
}

export const confirmOrder = (payload, onSuccess, onError) => {
  return axios.post(ApiCall.confirmOrder, payload, ApiCall.getConfigGetWithToken())
    .then(function (response) {
      if (response.data.success) {
        if (onSuccess) { onSuccess(response.data) }
      } else {
        if (onError) { onError(response.data) }
        if (response.error) {
          NotificationManager.error('System Error!','Error');
        } else {
          // NotificationManager.warning(response.data.message,'Warning');
        }
      }
      return response
    })
    .catch(function (error) {
      handleCommonError(error, getf5TokenFailure)
    });;
}

export const on2FA = (onSuccess) => {
  return axios.get(ApiCall.on2FA,{
    ...ApiCall.getConfigGetWithToken(),
  })
    .then(function (response) {
      // console.log(response)
      if (response.data.success) {
        if (onSuccess) { onSuccess(response.data.QRImage) }
      } else {
        
      }
      return response
    })
    .catch(function (error) {
      handleCommonError(error, getf5TokenFailure)
    });;
}

export const verify2FA = (payload,onSuccess) => {
  return axios.get(ApiCall.verify2FA,{
    ...ApiCall.getConfigGetWithToken(), params: payload,
  })
    .then(function (response) {
      // console.log(response)
      if (response.data.success) {
        if (onSuccess) { onSuccess(response.data.QRImage) }
      } else {
        
      }
      return response
    })
    .catch(function (error) {
      handleCommonError(error, getf5TokenFailure)
    });;
}

export const verifyReCapcha = (payload, onSuccess) => {
  return axios.post(ApiCall.verifyReCapcha, payload, ApiCall.getConfigGetWithToken())
    .then(function (response) {
      if (response.data.success) {
        if (onSuccess) { onSuccess(response.data) }
      } else {
        if (response.error) {
          NotificationManager.error('System Error!','Error');
        } else {
          // NotificationManager.warning(response.data.message,'Warning');
        }
      }
      return response
    })
    .catch(function (error) {
      handleCommonError(error, getf5TokenFailure)
    });;
}

export const loginTelegram = (payload, onSuccess, onError) => {
  return axios.post(ApiCall.loginTelegram, payload, ApiCall.getConfigGetWithToken())
    .then(function (response) {
      if (response.data.success) {
        if (onSuccess) { onSuccess(response.data) }
      } else {
        if (onError) { onError(response.data) }
        if (response.error) {
          NotificationManager.error('System Error!','Error');
        } else {
          // NotificationManager.warning(response.data.message,'Warning');
        }
      }
      return response
    })
    .catch(function (error) {
      handleCommonError(error, getf5TokenFailure)
    });;
}

export const accRename = (payload, onSuccess) => {
  return axios.post(ApiCall.rename, payload, ApiCall.getConfigGetWithToken())
    .then(function (response) {
      if (response.data.success) {
        if (onSuccess) { onSuccess() }
      } else {
        if (response.error) {
          NotificationManager.error('System Error!','Error');
        } else {
          NotificationManager.warning(response.data.message,'Warning');
        }
      }
      return response
    })
    .catch(function (error) {
      handleCommonError(error, getf5TokenFailure)
    });;
}