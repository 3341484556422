import React, { useEffect, useState, } from "react";
import { Table, Rate, Button, Card , Tooltip} from "antd";
import Widget from "components/Widget/index";
import { useSelector, useDispatch } from "react-redux";
import { listForkFetchStart, getallwalletStart } from "appRedux/actions/Api";
import { loveFork } from "appRedux/sagas/Api";
import { SocialIcon } from 'react-social-icons';
import HeartButton from "../../util/HeartButton"
import { host } from "../../constants/ApiServer";
import { random } from "lodash-es";

const ListForkComp = () => {
  const dispatch = useDispatch();
  const { listFork, listLoveF, loading } = useSelector(({ api }) => api)
  const { width } = useSelector(({ common }) => common);

  var oj = {}
  listFork.map(e => { oj[e.forkId] = false })
  const [clicks, setClicks] = useState(oj)

  useEffect(() => {
    // dispatch(listForkFetchStart())
    // dispatch(getallwalletStart())
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(listForkFetchStart())
    }, 10 * 1000 * 60);
    return () => clearInterval(interval);
  }, [])

  const columns = [
    {
      key: 'coin',
      title: "Coin",
      width:150,
      // dataIndex: "name",
      render: (item) => {
        return (
          <Tooltip placement="top" title={item.name}>
            <div className="gx-flex-row gx-align-items-center">
              <img
                className="gx-rounded-circle gx-size-20 gx-mr-2"
                src={host + item.picUrl}
                alt=""
              />
              <p className="gx-mb-0">{item.symbol}</p>
            </div>
          </Tooltip>
        );
      },
      sorter: (a, b) => (a.symbol.length - b.symbol.length),
    },
    {
      key: 'love',
      title: ` ❤️ Love 💬`,
      width: 110,
      // dataIndex: "name",
      defaultSortOrder: 'ascend',
      sorter: (a, b) => (listLoveF[a.forkId] - listLoveF[b.forkId]),
      render: (item) => {
        // console.log(item)
        return (
          <div className="gx-flex-row gx-align-items-center">
            <HeartButton click={clicks[item.forkId]} like={listLoveF[item.forkId]} onLike={(e) => {
              !clicks[item.forkId] && loveFork({ forkId: item.forkId }, () => {
                setClicks(prevState => ({
                  ...prevState,
                  [item.forkId]: true
                }));
              })
            }} />
          </div>
        );
      }
    },
    {
      key: 'price',
      title: "Price",
      dataIndex: "price",
      width: 70,
      sorter: (a, b) => a.price - b.price,
    },
    {
      key: 'netspace',
      title: "Netspace",
      dataIndex: "netspace",
      width: 100,
      sorter: (a, b) => a.netspace - b.netspace,
    },
    {
      key: 'height',
      title: "Height",
      dataIndex: "height",
      width: 80,
      sorter: (a, b) => a.height - b.height,
    },
    // {
    //   key: 'difficulty',
    //   title: "Diff",
    //   dataIndex: "difficulty",
    //   width: 60,
    //   sorter: (a, b) => a.difficulty - b.difficulty,
    // },
    {
      key: 'reward',
      title: "Reward",
      dataIndex: "reward",
      width: 40,
      sorter: (a, b) => a.reward - b.reward,
    }, {
      key: 'websiteLink',
      title: "Web",
      dataIndex: "websiteLink",
      width: 45,
      render: (item) => {
        return (
          <div className="gx-flex-row gx-align-items-center">
            <SocialIcon bgColor='#038fdd' url={item} style={{ height: 25, width: 25 }} />
          </div>
        );
      },
    },
    {
      key: 'websiteLink',
      title: "GitHub",
      dataIndex: "githubLink",
      width: 50,
      render: (item) => {
        return (
          <div className="gx-flex-row gx-align-items-center">
            <SocialIcon url={item} style={{ height: 25, width: 25 }} />
          </div>
        );
      },
    }
  ];

  return (
    <Widget>
      {/* <Rate/> */}
      <div className="gx-table-responsive">
        <Table
          className="gx-table-no-bordered"
          columns={columns}
          dataSource={listFork}
          pagination={false}
          bordered={false}
          size="middle"
        />
      </div>
    </Widget>
  );
};

export default ListForkComp;
