import React, { useState } from "react";
import { Button, Dropdown, Layout, Menu, message, Popover, Modal } from "antd";

// import Icon from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import CustomScrollbars from "util/CustomScrollbars";
import languageData from "../languageData";
import Walllet from "../../../routes/forkList/Walllet";
// import SearchBox from "components/SearchBox";
import UserInfo from "../../../routes/components/UserInfo";
// import AppNotification from "components/AppNotification";
// import MailNotification from "components/MailNotification";
import HorizontalNav from "../HorizontalNav";
import { Link, useHistory } from "react-router-dom";
import {
  switchLanguage,
  toggleCollapsedSideNav,
} from "../../../appRedux/actions/Setting";
// import IntlMessages from "../../../util/IntlMessages";
import { TAB_SIZE } from "../../../constants/ThemeSetting";
import ModalLogin from "../../ModalLogin";
import { headerMenuText } from "../../../styles/custom";
import Lottie from 'react-lottie';
import * as iconAnim from '../../../routes/forkP2p/icons/jsonIcons';


const { Header } = Layout;

const menu = (
  <Menu onClick={handleMenuClick}>
    <Menu.Item key="1">Products</Menu.Item>
    <Menu.Item key="2">Apps</Menu.Item>
    <Menu.Item key="3">Blogs</Menu.Item>
  </Menu>
);

function handleMenuClick(e) {
  message.info("Click on menu item.");
}

const BtnWallet = (props) => {
  const { click,icon,onClick } = props
  const [hover, setHover] = useState(false)
  return (
    <div
      onClick={onClick}
      style={{
        width: 46,
        height: 46,
        justifyContent: 'center',
        display: 'flex',
        backgroundColor: click ? '#84A459' : (hover ? '#a6cf6f':'#f5f5f5'),
        borderRadius: 10,
        border: click ? undefined : (hover ? undefined: '1px solid rgba(0, 0, 0, 0.2'),
        boxShadow: click || hover ? '2px 2px 8px -4px rgba(102,102,102,0.79)' : undefined
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}>
      <Lottie
        width={46}
        height={46}
        isStopped={!hover}
        speed={1}
        direction={1}
        options={{
          loop: true,
          autoplay: true,
          animationData: icon,
          preserveAspectRatio: 'xMidYMid slice'
        }}
      >
      </Lottie>
      
    </div>
  )
}

const InsideHeader = () => {
  const [searchText, setSearchText] = useState("");
  const { locale } = useSelector(({ settings }) => settings);
  const { navCollapsed, width } = useSelector(({ common }) => common);
  const authUser = useSelector(({ auth }) => auth.authUser);
  const dispatch = useDispatch();
  const history = useHistory();
  const [clickWallet,setClickWallet] = useState(false)
  const [visible, setVisible] = useState(false);
  const [showLogin, setshowLogin] = useState(false);
  const languageMenu = () => (
    <CustomScrollbars className="gx-popover-lang-scroll">
      <ul className="gx-sub-popover">
        {languageData.map((language) => (
          <li
            className="gx-media gx-pointer"
            key={JSON.stringify(language)}
            onClick={(e) => dispatch(switchLanguage(language))}
          >
            <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`} />
            <span className="gx-language-text">{language.name}</span>
          </li>
        ))}
      </ul>
    </CustomScrollbars>
  );
  const updateSearchChatUser = (evt) => {
    setSearchText(evt.target.value);
  };
  const topMenu = () => {
    if (authUser !== null) {
      return (
        <ul className="gx-header-notifications gx-ml-auto">
          <li >
            <BtnWallet click={clickWallet} icon={iconAnim.walletBag}
              onClick={() => {
                setVisible(true)
                setClickWallet(true)
              }} />
            <Modal
              visible={visible}
              destroyOnClose maskClosable={false}
              title={<strong style={{ display: 'flex', alignSelf: 'center', justifyContent: 'center' }}>MY WALLETS</strong>}
              footer={null}
              style={{ top: 50}}
              onCancel={() => {
                setVisible(false)
                setClickWallet(false)
              }}
              onOk={() => {
                setVisible(false)
                setClickWallet(false)
              }}
            >
              <Walllet style={{ paddingTop: 20 }}></Walllet>
            </Modal>

          </li>
          <li >
            <UserInfo />
          </li>
        </ul>)
    }
    else {
      return (
        <ul className="gx-header-notifications gx-ml-auto">
          <Button style={headerMenuText} ghost type="primary" onClick={() => setshowLogin(true)}>Login</Button>
          <Modal
            visible={showLogin}
            title={<strong style={{ display: 'flex', alignSelf: 'center', justifyContent: 'center' }}>LOGIN</strong>}
            footer={null}
            style={{ top: 50 }}
            onCancel={() => {
              setshowLogin(false)
            }}
            onOk={() => {
              setshowLogin(false)
            }}
          >
            <ModalLogin onDone={() => {
              setshowLogin(false)
            }}></ModalLogin>
          </Modal>
        </ul>


      )
    }
  }

  return (
    <div >
      <Header  >
        <div className="gx-container" style={{ paddingLeft: 0 }}>
          <div className="gx-header-horizontal-main-flex">
            <div className="gx-d-block gx-d-lg-none gx-linebar gx-mr-xs-3 6e">
              <i
                className="gx-icon-btn icon icon-menu"
                onClick={() => {
                  dispatch(toggleCollapsedSideNav(!navCollapsed));
                }}
              />
            </div>
            <Link
              to="/"
              className="gx-d-block gx-d-lg-none gx-pointer gx-mr-xs-3 gx-pt-xs-1 gx-w-logo" >
              <img alt="" src="/assets/images/logo.png" />
            </Link>
            <Link
              to="/"
              className="gx-d-none gx-d-lg-block gx-pointer gx-mr-xs-5 gx-logo"
            >
              <img alt="" src="/assets/images/logo.png" />
            </Link>

            {width >= TAB_SIZE && (
              <div className=" gx-header-horizontal-nav-curve">
                <HorizontalNav />
              </div>
            )}
            {topMenu()}
          </div>
        </div>
      </Header>
    </div >
  );
};

export default InsideHeader;
