import './heart.css'
import { useState } from 'react';

function HeartButton(props) {
  const [click, setClick] = useState(props.click)
  const [like, setLike] = useState(props.like)

  const onClick = (e) => {
    props.onLike && props.onLike()
    // setClick(!click)
    // let tmp = click ? like - 1 : like + 1
    // setLike(tmp)
  }

  return (
    <div className="heart-btn">
      <div className="content" onClick={onClick} >
        <span className={props.click ? "heart heart-active" : "heart"}></span>
        <span className="text">{props.like}</span>
      </div>
    </div>
  );
}

export default HeartButton;
