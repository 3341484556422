import React, { useState } from "react";
import { Select, Button, Space, Input, BackTop, Popconfirm,notification } from "antd";
import { host } from '../../../constants/ApiServer'
import { requestAirdrop,verifyReCapcha } from "../../../appRedux/sagas/Api";
import { listAirdropFetchStart } from "appRedux/actions/Api";
import { useSelector, useDispatch } from "react-redux";
import { ReCaptcha } from 'react-recaptcha-v3'


import Page from "../../Page";

const { Option } = Select

const GetAirDrop = ({ loveDairy, eventId, forks, wait }) => {
  const dispatch = useDispatch();
  const forkMake = (item) => { return <div> <img className="gx-rounded-circle gx-size-15 gx-mr-2" src={host + item.picUrl} alt="" />{item.symbol} </div> }
  const [addr, setAddr] = useState('')
  const [forkId_s, setForkId_s] = useState(0)
  const [defau, setDefau] = useState(null)
  const [checkCapcha, setCheckCapcha] = useState(false);

  const prefixSelector = (
    <Select defaultValue={defau} style={{ width: 110 }} onChange={(e) => {
      setForkId_s(Number(e));
      setAddr('');
    }}>
      {(forks !== undefined) && forks.map((item, index) => {
        if (!item.noWallet) {
          return (<Option key={index} value={item.forkId}> {forkMake(item)} </Option>);
        }
      })}
    </Select>
  );
  //  You have 5 LOVEs per day.
  // - LOVE your favorite coins every, every, every day.
  // - The number of times LOVE will be reset at 0: 00 - GMT
  //   - After expressing LOVE, you will be given 1 of 3 Forks.
  //   - Total Airdrops: COV: 50 turns - XFL: 50 turns - XFX : 50 turns.
  //     - Amount per turn: 0.0002 COV(or) 0.0002 XFL(or) 0.00001 XFX.
  //       Note: Each account or each IP can only be received Airdrop once within 10 hours.
  return (
    <Page requireLogin={true}>
      <ReCaptcha
        sitekey="6LdmkT0cAAAAAEW5B-MAFrofDAu_1kZTHfQM2-UK"
        action='GetAirdrop'
        verifyCallback={(capchaToken) => {
          verifyReCapcha({capchaToken:capchaToken},()=>{setCheckCapcha(true)})
        }}
      />
      <Space size="large" direction="vertical" >
        <ul>
          <li> <span>{`You have ${loveDairy} LOVEs per day.`}</span></li>
          <li> <span>{`LOVE your favorite coins every, every, every day.`}</span></li>
          <li> <span>{`The number of times LOVE will be reset at 0: 00 - GMT`}</span></li>
          <li> <span>{`After expressing LOVE, you will be given 1 of 3 Forks.`}</span></li>
          <li> <BackTop /> Total Airdrops:
            <strong className="gx-text-blue">
              {forks !== undefined ?
                `${forks[0].qty} turn ${forks[0].symbol}, ${forks[1].qty} turn ${forks[1].symbol}, ${forks[2].qty} turn ${forks[2].symbol}.` :
                ''}
            </strong>
          </li>
          <li> <BackTop />  Amount per turn:
            <strong className="gx-text-blue">
              {forks !== undefined ?
                `${forks[0].prize} ${forks[0].symbol} (or) ${forks[1].prize} ${forks[1].symbol} (or) ${forks[2].prize} ${forks[2].symbol}.` :
                ''}
            </strong>
          </li>
          <li> <span>We connect with other fork admins and airdrop regularly.</span> </li>
          <li> <BackTop /> Note: Each account or each IP can only be received once within <strong className="gx-text-blue"> {`${wait} hours.`} </strong> </li>
        </ul>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <p style={{ color: 'tomato'}}><b>You need to use 5 LOVE to receive the Airdrop.</b></p>
          <p style={{ color: '#84A459', fontStyle: 'italic' }}><b>Please enter the wallet address of the coin fork you want to receive.</b></p>

          <Input
            autoFocus
            size="large"
            onFocus={e => e.target.select()}
            addonBefore={prefixSelector}
            placeholder='Adrress Wallet'
            value={addr}
            onChange={(e) => { setAddr(e.target.value) }}
          />

          <Popconfirm
            title={`Please check back: [${addr}]`}
            style={{ justifyContent: 'center', alignItems: 'center' }}
            onConfirm={() => {
              if (!checkCapcha) return false;
              if (forkId_s === 0) {
                notification.warning({
                  message: 'Warning',
                  description: 'You have not selected the fork to receive the airdrop',
                });
              } else {
                setAddr('')
                setDefau(null)
                requestAirdrop({ forkId: forkId_s, forkAddress: addr, eventId: eventId }, () => {
                  setTimeout(() => {
                    dispatch(listAirdropFetchStart(eventId))
                  }, 1500)
                })
              }
            }}
            onCancel={() => {
              setAddr('')
              setDefau(null)
            }}
            okText="YES"
            cancelText="NO"
            cancelButtonProps={{ style: { width: 40 } }}
            okButtonProps={{ style: { width: 40 } }}
          >
            <Button style={{ marginBottom: 0, fontSize: 18, fontWeight: 700, marginTop: 20 }} type="primary" onClick={(e) => { return false }}> REQUEST </Button>
          </Popconfirm>

        </div>
      </Space>
    </Page >
  );
}

export default GetAirDrop;
