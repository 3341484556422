import { ConsoleSqlOutlined, FastForwardFilled } from "@ant-design/icons";
import * as ActionTypes from "../../constants/ActionTypes";
import { host } from "../../constants/ApiServer";
const _profile = JSON.parse(localStorage.getItem("profile"))
const INIT_STATE = {
  maintain: false,
  loading: {},
  profile: _profile === null ? null : _profile,
  listFork: [],
  listMartket: [],
  lstWallet: [],
  listEvent: {},
  listLoveF: {},
  listAirdrop: [],
  eventForkInfo :{},
  eventForkRemain: [],
  notiData: null
};

const updateLoading = (load, action, onoff) => {  
  load[action] = onoff
  return load
}

const updateWallet = (listFork, wallet) => {
  // console.log(wallet)
  if (wallet !== null)
    listFork.forEach(iFork => {
      if (iFork.forkId + '' === wallet.forkId + '') {
        iFork.address = wallet.address;
        iFork.balance = wallet.balance;
      }
    });
  return listFork
}

// {"cmd":"balance","data":{"accID":"cnow1hkum9tsvksg9t542goo","balance":{"5":79000000}},"sign":"bc2504eac9fa11c9ffa415761a8d9f1a"}
const updateWalletWS = (listFork, wallet) => {
  // console.log(wallet)
  if (wallet !== null)
    listFork.forEach(iFork => {
      // console.log(iFork.forkId, wallet.balance[iFork.forkId])
      iFork.balance = wallet.balance[iFork.forkId] !== undefined ? wallet.balance[iFork.forkId] :0
    });
  //  console.log(listFork)
  return listFork
}


const updateLove = (listFork) => {
  var oj = {}
  listFork.map(e => { oj[e.forkId] = e.loveCnt })
  return oj
}

const updateLoveCount = (listLoveF, loveTap) => {
  listLoveF[loveTap.forkId] = loveTap.loveCnt
  return listLoveF
}

const updateEventForkRemain = (forks, listFork) => {
  var out = forks.map(e => {
    let f = listFork.find(r=>r.forkId === e.forkId)
    return {...e, ...{symbol:f.symbol}}
  })
  return out
}

const updateEventForkInfo = (eventDataJson, listFork) => {
  const objJson = JSON.parse(eventDataJson)
  var out = {
    wait : objJson.wait,
    loveDairy : objJson.loveDairy,
    forks : objJson.forks.map(e => {
      let f = listFork.find(r=>r.forkId === e.forkId)
      return {...e,...{symbol: f.symbol, picUrl: f.picUrl}}
    })
  }
  return out
}


const marketFilter = (data, mklst) => {
  
  const { profile, myOrder, forkForm, forkTo, price, amount } = data;
  
  let filterMK = mklst.filter(e => {
    return (forkTo && e.forkSwapId === forkTo.forkId) || !forkTo

        // ((forkTo && e.forkSwapId === forkTo.forkTo) || !forkTo) 
        // (e.priceSwap <= price) || (e.amountSwap <= amount))
  })
  return (filterMK)
}


const ApiReducer = (state = INIT_STATE, action) => {

  switch (action.type) {
    case ActionTypes.OFF_NOTIFICATION:
      return {
        ...state,
        notiData: null
      };
    case ActionTypes.MAINTAIN:
      return {
        ...state,
        maintain: true
      };
    case ActionTypes.ON_API_LOADING:
      return {
        ...state,
        loading: updateLoading(state.loading, action.payload, true)
      };
    case ActionTypes.OFF_API_LOADING:
      return {
        ...state,
        loading: updateLoading(state.loading, action.payload, false)
      };
    case ActionTypes.PROFILE_FETCH_START:
      return {
        ...state,
      };
    case ActionTypes.PROFILE_FETCH_SUCCESS:
      // console.log("PROFILE_FETCH_SUCCESS", action.payload)
      return {
        ...state,
        profile: action.payload,
      };
    case ActionTypes.PROFILE_FETCH_ERROR:
      return {
        ...state,
      };
    case ActionTypes.LISTFORK_FETCH_START:
      return {
        ...state,
      };
    case ActionTypes.LISTFORK_FETCH_SUCCESS:
      // console.log("LISTFORK_FETCH_SUCCESS", action.payload)
      return {
        ...state,
        listFork: action.payload,
        listLoveF: updateLove(action.payload)
      };
    case ActionTypes.LISTFORK_FETCH_ERROR:
      return {
        ...state,
      };
    case ActionTypes.LIST_AIRDROP_FETCH_START:
      return {
        ...state,
      };
    case ActionTypes.LIST_AIRDROP_FETCH_SUCCESS:
      // console.log("LIST_AIRDROP_FETCH_SUCCESS", action.payload)
      return {
        ...state,
        listAirdrop: action.payload.listApproves,
        eventForkRemain: updateEventForkRemain(action.payload.forks, state.listFork),
      };
    case ActionTypes.LIST_AIRDROP_FETCH_ERROR:
      return {
        ...state,
      };
    case ActionTypes.GET_EVENTS_FETCH_START:
      return {
        ...state,
      };
    case ActionTypes.GET_EVENTS_FETCH_SUCCESS:
      // console.log("GET_EVENTS_FETCH_SUCCESS", action.payload)
      return {
        ...state,
        listEvent: action.payload[0],
        loading: updateLoading(state.loading, ActionTypes.GET_EVENTS_FETCH_START, 0 === 1),
        eventForkInfo: updateEventForkInfo(action.payload[0].data, state.listFork),
      };
    case ActionTypes.GET_EVENTS_FETCH_ERROR:
      return {
        ...state,
      };
    case ActionTypes.GET_WALLET_FOLK_INFO_START:
      return {
        ...state,
      };
    case ActionTypes.GET_WALLET_FOLK_INFO_SUCCESS:
      return {
        ...state,
        listFork: updateWallet(state.listFork, action.payload.wallet)
      };
    case ActionTypes.GET_WALLET_FOLK_INFO_ERROR:
      return {
        ...state,
      };
    case ActionTypes.WS_BALANCE:
      // console.log(action.payload)
      return {
        ...state,
        listFork: updateWalletWS(state.listFork, action.payload)
      };
    case ActionTypes.WS_LOVE_FORK:
      return {
        ...state,
        listLoveF: updateLoveCount(state.listLoveF, action.payload)
      };
    case ActionTypes.WS_NOTIFICATION:
      return {
        ...state,
        notiData: action.payload,
      };
    case ActionTypes.GET_MARKETS_START:
      return {
        ...state,
      };
    case ActionTypes.GET_MARKETS_SUCCESS:
      // console.log("GET_MARKETS_SUCCESS", action.payload)
      return {
        ...state,
        listMartket: action.payload,
        // loading: updateLoading(state.loading,ActionTypes.GET_MARKETS_START,false)
      };
    case ActionTypes.MARKETS_FILTER:
      return {
        ...state,
        listMartket: marketFilter(action.payload,state.listMartket)
      };
      case ActionTypes.SIGNOUT_USER_SUCCESS: {
        // console.log('logout ApiReducer',state.listFork)
        return {
          ...state,
          lstWallet: [],
          listFork: [],
          loading: {},
        };
      }
    default:
      return state;
  }
};

export default ApiReducer;
