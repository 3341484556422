import {
  FETCH_ERROR, 
  FETCH_START, 
  FETCH_SUCCESS, 
  HIDE_MESSAGE, 
  SHOW_MESSAGE,

  UPDATE_COUNTER,
  START_COUNTER,
  COUNTER_STARTED,
  PAUSE_COUNTER,
  COUNTER_PAUSED,
  RESUME_COUNTER,
  COUNT_OVER,

} from "../../constants/ActionTypes";

export const fetchStart = () => {
  return {
    type: FETCH_START
  }
};

export const fetchSuccess = () => {
  return {
    type: FETCH_SUCCESS
  }
};

export const fetchError = (error) => {
  return {
    type: FETCH_ERROR,
    payload: error
  }
};

export const showMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  }
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE
  }
};

export const startCounter = secs => ({
  type: START_COUNTER,
  payload: { secs }
});

export const counterStarted = () => ({
  type: COUNTER_STARTED
});

export const updateCounter = secs => ({
  type: UPDATE_COUNTER,
  payload: secs
});

export const pauseCounter = () => ({
  type: PAUSE_COUNTER
});

export const counterPaused = () => ({
  type: COUNTER_PAUSED
});

export const countOver = () => ({
  type: COUNT_OVER
});






