import { all,fork } from "redux-saga/effects";
import authSagas from "./Auth";
import getProfileInfo from "./Api";
import wsSagas from "./Ws";
import watchCountdown from './CountDown';


export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    getProfileInfo(),
    wsSagas(),
    fork(watchCountdown)
  ]);
}
