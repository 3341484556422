import React from "react";
import { Route, Switch } from "react-router-dom";

import ForkList from "./forkList/index";
import ForkP2P from "./forkP2p/index";
import Game from "./games/index";

const App = ({ match }) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}fork-list`} component={ForkList} />
      <Route path={`${match.url}fork-p2p`} component={ForkP2P} />
      <Route path={`${match.url}game`} component={Game} />
    </Switch>
  </div>
);

export default App;
