import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { authApi } from "../../constants/ApiServer";
import {
  SIGNIN_FACEBOOK_USER,
  SIGNIN_GOOGLE_USER,
  SIGNIN_USER,
  SIGNOUT_USER,
  SIGNUP_USER,
} from "constants/ActionTypes";
import {
  showAuthMessage,
  userSignInSuccess,
  userSignOutSuccess,
  userSignUpSuccess,
} from "../../appRedux/actions/Auth";
import {
  userFacebookSignInSuccess,
  userGithubSignInSuccess,
  userGoogleSignInSuccess,
  userTwitterSignInSuccess,
} from "../actions/Auth";
import { profileFetchStart } from "../actions/Api";
import { SIGNIN_USER_SUCCESS, SIGNOUT_USER_SUCCESS } from "../../constants/ActionTypes";
import { wsConnect } from "../actions/Websocket";

// const createUserWithEmailPasswordRequest = async (email, password) =>
//   await auth
//     .createUserWithEmailAndPassword(email, password)
//     .then((authUser) => authUser)
//     .catch((error) => error);

const signInUserWithEmailPasswordRequest = (payload) => {
  const refCode = sessionStorage.getItem("refcode")
  if (refCode != null && refCode !== undefined) {
    payload = { ...payload, refCode: refCode }
  }
//TODO: TÙNG Fix

  return axios.post(authApi.login, payload)
    .then(function (response) {
      return response.data;
    });

}

// const signOutRequest = async () =>
//   await auth
//     .signOut()
//     .then((authUser) => authUser)
//     .catch((error) => error);

// const signInUserWithGoogleRequest = async () =>
//   await auth
//     .signInWithPopup(googleAuthProvider)
//     .then((authUser) => authUser)
//     .catch((error) => error);

// const signInUserWithFacebookRequest = async () =>
//   await auth
//     .signInWithPopup(facebookAuthProvider)
//     .then((authUser) => authUser)
//     .catch((error) => error); 

function* createUserWithEmailPassword({ payload }) {
  const { email, password } = payload;
  try {
    // const signUpUser = yield call(
    //   createUserWithEmailPasswordRequest,
    //   email,
    //   password
    // );
    const signUpUser = {}
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      localStorage.setItem("token", signUpUser.user.uid);
      yield put(userSignUpSuccess(signUpUser.user.uid));

    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithGoogle() {
  try {
    // const signUpUser = yield call(signInUserWithGoogleRequest);
    const signUpUser = "test"
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      localStorage.setItem("token", signUpUser.user.uid);
      yield put(userGoogleSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

// function* signInUserWithFacebook() {
//   try {
//     // const signUpUser = yield call(signInUserWithFacebookRequest);
//     const signInUser = {}
//     if (signUpUser.message) {
//       yield put(showAuthMessage(signUpUser.message));
//     } else {
//       localStorage.setItem("token", signUpUser.user.uid);
//       yield put(userFacebookSignInSuccess(signUpUser.user.uid));
//     }
//   } catch (error) {
//     yield put(showAuthMessage(error));
//   }
// }
// function* connectWss({ payload }) {

//   try {
//     const signInUser = yield call(
//       signInUserWithEmailPasswordRequest,
//       payload
//     );
//     // console.log(signInUser)
//     if (signInUser.message) {
//       //yield put(showAuthMessage(signInUser.message));
//     } else {
//       localStorage.setItem("token", signInUser.accessToken);
//       yield put(userSignInSuccess(signInUser.accessToken));
//     }
//   } catch (error) {
//     yield put(showAuthMessage(error));
//   }
// }
function* signInUserWithEmailPassword({ payload }) {

  try {
    const signInUser = yield call(
      signInUserWithEmailPasswordRequest,
      payload
    );
    // console.log(signInUser)
    if (signInUser.message) {
      //yield put(showAuthMessage(signInUser.message));
    } else {
      localStorage.setItem("refreshToken", signInUser.refreshToken);
      localStorage.setItem("token", signInUser.accessToken);
      yield put(userSignInSuccess(signInUser.accessToken));
      yield put(wsConnect(signInUser.accessToken));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signOut() {
  try {
    // const signOutUser = yield call(signOutRequest);
    const signOutUser = undefined
    if (signOutUser === undefined) {
      // localStorage.removeItem("token");
      // localStorage.removeItem("profile");
      sessionStorage.clear()
      localStorage.clear()
      yield put(userSignOutSuccess(signOutUser));
    } else {
      yield put(showAuthMessage(signOutUser.message));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

export function* createUserAccount() {
  yield takeEvery(SIGNUP_USER, createUserWithEmailPassword);
}

export function* signInWithGoogle() {
  yield takeEvery(SIGNIN_GOOGLE_USER, signInUserWithGoogle);
}

// export function* signInWithFacebook() {
//   yield takeEvery(SIGNIN_FACEBOOK_USER, signInUserWithFacebook);
// }
// export function* signInUserSuccess() {
//   yield takeEvery(SIGNIN_USER_SUCCESS, connectWss);
// }
export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}
function* reloadApp({ payload }) {
  try {
    window.location.reload(true)
  } catch (error) {
  
  }
}

export function* signOutUserSuccess() {
  yield takeEvery(SIGNOUT_USER_SUCCESS, reloadApp);
}

export default function* rootSaga() {
  yield all([
    fork(signInUser),
    fork(createUserAccount),
    fork(signInWithGoogle),
    fork(signOutUserSuccess),
    // fork(signInUserSuccess),
    
    fork(signOutUser),
  ]);
}
