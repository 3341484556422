// Customizer const
export const TOGGLE_COLLAPSED_NAV = "TOGGLE_COLLAPSE_MENU";
export const WINDOW_WIDTH = "WINDOW-WIDTH";
export const SWITCH_LANGUAGE = "SWITCH-LANGUAGE";

//Contact Module const
export const FETCH_START = "fetch_start";
export const FETCH_SUCCESS = "fetch_success";
export const FETCH_ERROR = "fetch_error";
export const SHOW_MESSAGE = "SHOW_MESSAGE";
export const HIDE_MESSAGE = "HIDE_MESSAGE";
export const ON_SHOW_LOADER = "ON_SHOW_LOADER";
export const ON_HIDE_LOADER = "ON_HIDE_LOADER";

//Auth const
export const SIGNUP_USER = "SIGNUP_USER";
export const SIGNUP_USER_SUCCESS = "SIGNUP_USER_SUCCESS";
export const SIGNIN_GOOGLE_USER = "SIGNIN_GOOGLE_USER";
export const SIGNIN_GOOGLE_USER_SUCCESS = "SIGNIN_GOOGLE_USER_SUCCESS";
export const SIGNIN_FACEBOOK_USER = "SIGNIN_FACEBOOK_USER";
export const SIGNIN_FACEBOOK_USER_SUCCESS = "SIGNIN_FACEBOOK_USER_SUCCESS";
export const SIGNIN_TWITTER_USER = "SIGNIN_TWITTER_USER";
export const SIGNIN_TWITTER_USER_SUCCESS = "SIGNIN_TWITTER_USER_SUCCESS";
export const SIGNIN_GITHUB_USER = "SIGNIN_GITHUB_USER";
export const SIGNIN_GITHUB_USER_SUCCESS = "signin_github_user_success";
export const SIGNIN_USER = "SIGNIN_USER";

export const SIGNIN_USER_SUCCESS = "SIGNIN_USER_SUCCESS";

export const SIGNOUT_USER = "SIGNOUT_USER";

export const SIGNOUT_USER_SUCCESS = "SIGNOUT_USER_SUCCESS";
export const INIT_URL = "INIT_URL";

export const REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS";


// WEBSOCKET
export const WS_CONNECT = "WS_CONNECT";
export const WS_DISCONNECT = "WS_DISCONNECT";
export const WS_RESCONNECT = "WS_RESCONNECT";
export const WS_BALANCE = "WS_BALANCE";
export const WS_LOVE_FORK = "WS_LOVE_FORK";
export const WS_NOTIFICATION = "WS_NOTIFICATION";


//Sticky
export const GET_STICKY = "GET_STICKY";
export const NOTES_UPDATE = "NOTES_UPDATE";
export const FETCH_ALL_NOTES_SUCCESS = "FETCH_ALL_NOTES_SUCCESS";
export const UPDATE_ALL_NOTES_SUCCESS = "UPDATE_ALL_NOTES_SUCCESS";

//Contact
export const GET_All_CONTACT_SUCCESS = "GET_All_CONTACT_SUCCESS";
export const ON_ADD_CONTACT_SUCCESS = "ON_ADD_CONTACT_SUCCESS";
export const UPDATE_CONTACT_SUCCESS = "UPDATE_CONTACT_SUCCESS";
export const DELETE_CONTACT_SUCCESS = "DELETE_CONTACT_SUCCESS";

//SOCKET 
export const INITIALIZE_WEB_SOCKETS_CHANNEL = "INITIALIZE_WEB_SOCKETS_CHANNEL";
export const WEBSOCKET_MESSAGE_RECEIVED = "WEBSOCKET_MESSAGE_RECEIVED";

//profile
export const PROFILE_FETCH_START = "PROFILE_FETCH_START";
export const PROFILE_FETCH_SUCCESS = "PROFILE_FETCH_SUCCESS";
export const PROFILE_FETCH_ERROR = "PROFILE_FETCH_ERROR";

//listFork
export const LISTFORK_FETCH_START = "LISTFORK_FETCH_START";
export const LISTFORK_FETCH_SUCCESS = "LISTFORK_FETCH_SUCCESS";
export const LISTFORK_FETCH_ERROR = "LISTFORK_FETCH_ERROR";

//list Airdrop
export const LIST_AIRDROP_FETCH_START = "LIST_AIRDROP_FETCH_START";
export const LIST_AIRDROP_FETCH_SUCCESS = "LIST_AIRDROP_FETCH_SUCCESS";
export const LIST_AIRDROP_FETCH_ERROR = "LIST_AIRDROP_FETCH_ERROR";

//GET_EVENTS 
export const GET_EVENTS_FETCH_START = "GET_EVENTS_FETCH_START";
export const GET_EVENTS_FETCH_SUCCESS = "GET_EVENTS_FETCH_SUCCESS";
export const GET_EVENTS_FETCH_ERROR = "GET_EVENTS_FETCH_ERROR";

//GET_ALL_WALLET
export const GET_ALL_WALLET_START = "GET_ALL_WALLET_START";
export const GET_ALL_WALLET_SUCCESS = "GET_ALL_WALLET_SUCCESS";
export const GET_ALL_WALLET_ERROR = "GET_ALL_WALLET_ERROR";

// GET_WALLET_FOLK_INFO
export const GET_WALLET_FOLK_INFO_START = "GET_WALLET_FOLK_INFO_START";
export const GET_WALLET_FOLK_INFO_SUCCESS = "GET_WALLET_FOLK_INFO_SUCCESS";
export const GET_WALLET_FOLK_INFO_ERROR = "GET_WALLET_FOLK_INFO_ERROR";

// GET_WALLET_FOLK_INFO
export const LOVE_FORK_START = "LOVE_FORK_START";
export const LOVE_FORK_SUCCESS = "LOVE_FORK_SUCCESS";
export const LOVE_FORK_ERROR = "LOVE_FORK_ERROR";

// GET_WALLET_FOLK_INFO
export const REQUEST_AIRDROP_START = "REQUEST_AIRDROP_START";
export const REQUEST_AIRDROP_SUCCESS = "REQUEST_AIRDROP_SUCCESS";
export const REQUEST_AIRDROP_ERROR = "REQUEST_AIRDROP_ERROR";

// GET_WALLET_FOLK_INFO
export const WITHDRAW_START = "WITHDRAW_START";
export const WITHDRAW_SUCCESS = "WITHDRAW_SUCCESS";
export const WITHDRAW_ERROR = "WITHDRAW_ERROR";

export const ON_API_LOADING = "ON_API_LOADING";
export const OFF_API_LOADING = "OFF_API_LOADING";


export const UPDATE_COUNTER = 'UPDATE_COUNTER';
export const START_COUNTER = 'START_COUNTER';
export const COUNTER_STARTED = 'COUNTER_STARTED';
export const PAUSE_COUNTER = 'PAUSE_COUNTER';
export const COUNTER_PAUSED = 'COUNTER_PAUSED';
export const RESUME_COUNTER = 'RESUME_COUNTER';
export const COUNT_OVER = 'COUNT_OVER';

export const GET_MARKETS_START = "GET_MARKETS_START";
export const GET_MARKETS_SUCCESS = "GET_MARKETS_SUCCESS";
export const GET_MARKETS_ERROR = "GET_MARKETS_ERROR";
export const MARKETS_FILTER = "MARKETS_FILTER";

export const MAINTAIN = "MAINTAIN";
export const OFF_NOTIFICATION = "OFF_NOTIFICATION";

