import React from "react";

// import {
//   Area,
//   AreaChart,
//   Line,
//   LineChart,
//   ResponsiveContainer,
//   Tooltip,
// } from "recharts";
import Auxiliary from "util/Auxiliary";


const ForkP2P = () => {
  return (
    <Auxiliary>
      <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center' }}>
        <img alt="example" style={{ maxWidth: 460, height: "auto", display: "block", marginLeft: "auto", marginRight: "auto" }} src="https://res.cloudinary.com/chianow-jsc/image/upload/v1630491782/chianow-logo-text.png" />
        <h1> </h1>
        <h1><strong> Entertainment center for farmers, fun, entertainment, high profit...</strong></h1>
        <h1> </h1>
        <h1> </h1>
        <h1> </h1>
        <h1 style={{ color: 'darkcyan' }}><strong> COMING SOON . . .</strong></h1>

      </div >
    </Auxiliary>
  );
};
export default ForkP2P;
