import React from "react";
import { Input, Tooltip } from "antd";
import { formatNumber } from "../../util/Tools"
import { isNull } from "lodash-es";

export default class NumericInput extends React.Component {
  onChange = e => {
    const { value } = e.target;
    const reg = /^-?\d*(\.\d*)?$/;
    if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
      this.props.onChange(value);
    }
  };

  // '.' at the end or only '-' in the input box.
  onBlur = () => {
    const { value, onBlur, onChange } = this.props;
    if (value === null || value === '' || value === undefined) return
    let valueTemp = value;
    if (value.charAt(value.length - 1) === '.'
      // || value === '-'
    ) {
      valueTemp = value.slice(0, -1);
    }
    onChange(valueTemp.replace(/0*(\d+)/, '$1'));
    if (onBlur) {
      onBlur();
    }
  };

  render() {
    // const { value } = this.props;
    // const title = value ? (
    //   <span className="numeric-input-title">{value !== '-' ? formatNumber(value) : '-'}</span>
    // ) : (
    //   'Input a number'
    // );
    return (
      <Input
        {...this.props}
        
        onChange={this.onChange}
        onBlur={this.onBlur}
        maxLength={25}
      />
    );
  }
}