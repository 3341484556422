import React from "react";
import { Col, Row } from "antd";
import Auxiliary from "util/Auxiliary";
import ListForkComp from "./ListForkComp";
import AirdropList from "../events/airdrop";


const ForkList = () => {
  return (
    <Auxiliary>
      <Row>
        <Col xl={14} lg={24} md={24} sm={24} xs={24}>
          {/* <DownloadMobileApps /> */}
          <ListForkComp />
        </Col>
        <Col xl={10} lg={24} md={24} sm={24} xs={24}>
          <AirdropList />
        </Col>
      </Row>
    </Auxiliary>
  );
};
export default ForkList;
