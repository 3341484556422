import './icons/cursor.css'

import React, { useState,useEffect } from "react";
import { host } from "../../constants/ApiServer";
import Auxiliary from "util/Auxiliary";
import { useSelector, useDispatch } from "react-redux";
import { getMarketsStart } from "../../appRedux/actions/Api"
import { unix_to_short_date } from "../../util/Tools"
import { Tabs, Col, Row, Table, Tooltip, Typography, Pagination } from "antd";
import CreateOrder from "./CreateOrder";
import MarketTable from "./MarketTable";
import * as iconAnim from './icons/jsonIcons';
import Lottie from 'react-lottie';

const BtnTab = (props) => {
  const { click,icon } = props
  const [hover, setHover] = useState(false)
  return (
    <div style={{
      width: 50,
      height: 50,
      justifyContent: 'center',
      display: 'flex',
      backgroundColor: click ? 'white' : (hover ? '#f5f5f5' : '#ececec'),
      borderRadius: 10,
      border: click ? undefined : '1px solid rgba(0, 0, 0, 0.1',
      boxShadow: click ? '2px 2px 8px -4px rgba(102,102,102,0.79)' : undefined
    }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}>
      <Lottie
        width={50}
        height={50}
        isStopped={!hover}
        speed={1}
        direction={1}
        options={{
          loop: true,
          autoplay: true,
          animationData: icon,
          preserveAspectRatio: 'xMidYMid slice'
        }}
      >
      </Lottie>
    </div>
  )
}
const ForkP2P = () => {
  const [tabClick, setTabClick] = useState('order')
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMarketsStart())
  }, [])
  return (
    <Auxiliary>
      <Col>
        <Tabs type='line' size={"large"} tabPosition='left' onChange={e => setTabClick(e)}>
          <Tabs.TabPane
            tab={<BtnTab click={tabClick === "order"} icon={iconAnim.coin_outline} />}
            key="order">
            <Row style={{ justifyContent: 'center' }}>
              <CreateOrder />
            </Row>
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={<BtnTab click={tabClick === "market"} icon={iconAnim.recycling_outline} />}
            key="market">
            <Col flex='auto'>
              <MarketTable/>
            </Col>
          </Tabs.TabPane>
        </Tabs>
      </Col>
    </Auxiliary>
  );
};
export default ForkP2P;