export const baseUrl = "https://backend.chianow.net:1313/api";
export const host = "https://backend.chianow.net:1313";
export const authApi = {
    login: baseUrl + "/auth/login",
    refreshToken: baseUrl + "/auth/f5token"
}
export const getConfigGetWithToken = () => {
    const token = localStorage.getItem("token")

    return {
        headers: { Authorization: `Bearer ${token}` }
    }
}
export const getProfileApi = baseUrl + "/acc/profile";
export const getWalletForkInfo = baseUrl + "/acc/getwallet";
export const getListAirdrop = baseUrl + "/fork/getairdrops";
export const getListFork = baseUrl + "/fork/getall";
// export const getAllWallet = baseUrl + "/auth/getallwallets";
export const initAddress = baseUrl + "/acc/initaddress";

// export const getEvents = baseUrl + "/fork/getevents";
export const getEvents = baseUrl + "/event/getcurent";
export const requestAirdrop = baseUrl + "/fork/requestairdrop";
export const loveFork = baseUrl + "/fork/love";
export const getWalletHistory = baseUrl + "/acc/getWalletHistory";
export const orderTrade = baseUrl + "/market/order";
export const getMarkets = baseUrl + "/market/getall";
export const delOrder = baseUrl + "/market/delorder";
export const withDraw = baseUrl + "/acc/withdraw";
export const confirmOrder = baseUrl + "/market/confirmorder";
export const on2FA = baseUrl + "/acc/on2fa";
export const verify2FA = baseUrl + "/acc/verify2FA";
export const withDrawOTP = baseUrl + "/acc/withdrawotp";
export const verifyReCapcha = baseUrl + "/auth/verifyrecapcha";
export const loginTelegram = baseUrl + "/auth/logintele";
export const rename = baseUrl + "/acc/rename";


