
export const converTime = (sec) => {
    if (!sec || sec === undefined) return ('00:00:00')
    let days = parseInt(sec / 86400, 10)
    let hours = parseInt((sec % 86000) / 3600, 10)
    let minutes = parseInt(((sec % 86000) % 3600) / 60, 10)
    let seconds = parseInt(((sec % 86000) % 3600) % 60, 10)
    days = days > 0 ? days + 'd,' : ''
    hours = hours < 10 ? '0' + hours : hours
    minutes = minutes < 10 ? '0' + minutes : minutes
    seconds = seconds < 10 ? '0' + seconds : seconds
    return (days + hours + ':' + minutes + ':' + seconds)
}

export function unix_to_short_date(unix_timestamp) {
  let d = new Date(unix_timestamp);
  return (
    d.toLocaleTimeString("en-US", { hour12: false }) +
    ", " +
    d.toLocaleDateString("en-US", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit"
    }) 
  );
}

export const unMojo = (forkId, balance) => {
  const rate = (forkId === 5) ? 1 / 1e8 : 1 / 1e12 //TODO : tỉ lệ mojo
  return (forkId === 5) ?
    (balance * rate).toLocaleString('en-US', {
      minimumFractionDigits: 8,
    }) :
    (balance * rate).toLocaleString('en-US', {
      minimumFractionDigits: 8,
    })
}

export const formatNumber = (value) => {
  value += '';
  const list = value.split('.');
  const prefix = list[0].charAt(0) === '-' ? '-' : '';
  let num = prefix ? list[0].slice(1) : list[0];
  let result = '';
  while (num.length > 3) {
    result = `,${num.slice(-3)}${result}`;
    num = num.slice(0, num.length - 3);
  }
  if (num) {
    result = num + result;
  }
  return `${prefix}${result}${list[1] ? `.${list[1]}` : ''}`;
}

export const nu = (n,value, len) => {
  if (value === null || value === '' || value === undefined) return (0).toFixed(n);
  let result = parseFloat(value).toFixed(n);
  let v = (len && len > 0 ? result.substring(0, len) : result)
  return v
}

export const delay = ms => new Promise(resolve => setTimeout(resolve, ms))