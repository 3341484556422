/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/heading-has-content */
import React, { useState, useEffect,useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Table, Typography, Tooltip, Button, BackTop } from "antd";
import Widget from "components/Widget/index";
import * as ActionTypes from "../../../constants/ActionTypes";
import { converTime, unix_to_short_date } from "../../../util/Tools";
import { startCounter,updateCounter } from "../../../appRedux/actions/Common";

const TableAirdrop = ({ onClick }) => {
  const dispatch = useDispatch();
  const {
    loading,
    listAirdrop,
    listEvent,
    eventForkRemain
  } = useSelector(({ api }) => api);
  const { secs } = useSelector(({ common }) => common);

  const columns = [
    { title: "Coin", width: 55, dataIndex: "symbol" },
    {
      title: "Addr", width: 250,
      render: (item) => {
        return (
          <Tooltip placement="top" title={item.address}>
            <Typography.Text ellipsis={true} style={{ width: 250 }}>
              {item.address}
            </Typography.Text>
          </Tooltip>
        )
      },
    },
    { title: "Rewards", width: 90, dataIndex: "amount", },
    // { title: "Time", width: 120, dataIndex: "timeStamp", },
    {
      title: "Time", width: 140, render: (item) => {
        return (
          <li>{`${unix_to_short_date(new Date(item.timeStamp))}`}</li>
        )
      },
    },
  ];
  var s = parseInt((Date.parse(listEvent.stopTime) - Date.now()) / 1000)
  useEffect(() => {
    if (listEvent.stopTime !== undefined) {
      dispatch(startCounter(s))
    }
  }, [listEvent.stopTime, secs])

  return (
    <Widget
      loading={loading[ActionTypes.GET_EVENTS_FETCH_START]}
      title={<strong style={{ display: 'flex', alignSelf: 'center', justifyContent: 'center' }}>AIRDROP LIST</strong>} >
      <Table
        className="gx-table-no-bordered"
        columns={columns}
        dataSource={listAirdrop}
        pagination={false}
        bordered={false}
        size="small"
        scroll={{ y: 500 }}
      />
      <div style={{ marginLeft: 0, marginTop: 20 }}>
        <li> <BackTop /> - Amount per turn: <strong className="gx-text-blue"> {eventForkRemain.map(r => ` ${r.prize}  ${r.symbol} - `)} </strong> </li>
        <h4 />
        <li> <BackTop /> - Remaining airdrop  : <strong className="gx-text-blue"> {eventForkRemain.map(r => ` ${r.symbol}: ${r.qty} - `)} </strong> </li>
        <h4 />
        <li> <BackTop /> - Time remaining: <strong className="gx-text-blue"> {` ${converTime(secs)}`} </strong> </li>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 30 }}>
        <Button style={{ marginBottom: 0, fontSize: 18, fontWeight: 700 }} size="large" type='primary'
          onClick={onClick}> REWARD </Button>
      </div>
    </Widget>)
};

export default TableAirdrop;

