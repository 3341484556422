import React,{useState, useEffect} from "react";
import { host } from "../../constants/ApiServer";
import Widget from "../../components/Widget/index";
import { useSelector, useDispatch } from "react-redux";
import { Col, Row, Tabs, Select, Space, Button, Slider, BackTop, Modal } from "antd";
import { NotificationManager } from "react-notifications";
import { formatNumber, nu, unMojo } from "../../util/Tools";
import { orderTrade } from "../../appRedux/sagas/Api"
import { getMarketsStart } from "../../appRedux/actions/Api"
import { getWalletForkInfoStart } from "appRedux/actions/Api";
import * as iconAnim from './icons/jsonIcons';
import Lottie from 'react-lottie';
import SelectCoin from './SelectCoin';
import {
  ClockCircleTwoTone
} from '@ant-design/icons';

import { Arrow } from "./icons/scrollArrow"
import NumericInput from "../components/NumericInput"

const CreateOrder = (props) => {
  const dispatch = useDispatch();
  const { listFork } = useSelector(({ api }) => api)
  const [forkForm, setForkForm] = useState(null)
  const [forkTo, setForkTo] = useState(null)
  const [amountForm, setAmountForm] = useState('0.000000')
  const [amountTo, setAmountTo] = useState('0.000000')
  const [minAmountOk, setMinAmountOk] = useState(null)
  const [priceForm, setPriceForm] = useState('0.00000000')
  const [priceTo, setPriceTo] = useState('0.00000000')
  const [sugPrice, setSugPrice] = useState(0)
  const [profit, setProfit] = useState(6)
  const [minAmountList, setMinAmountList] = useState([])
  const [timeList, setTimeList] = useState([])
  const [timeSelect, setTimeSelect] = useState(1)

  useEffect(() => {
    const tmpAMTlst = []
    for (let i = 10; i <= 100; i += 10) { tmpAMTlst.push({ value: i / 100, str: i + '%' }) }
    setMinAmountList(tmpAMTlst)

    const tmpTimelst = []
    for (let i = 1; i <= 48; i++) { tmpTimelst.push({ value: i, str: i + 'h' }) }
    setTimeList(tmpTimelst)
  }, [])
    
  const marks = {
    0: 'Slow',
    1: '◦',
    2: '◦',
    3: '◦',
    4: '◦',
    5: '◦',
    6: 'Medium',
    7: '◦',
    8: '◦',
    9: '◦',
    10: {
      style: { color: '#84A459' },
      label: <strong>Fast</strong>,
    },
  };

  const showConfirmOrder = () => {
    Modal.confirm({
      title: 'Please confirm this transaction information',
      content: (
        // <ul>
        <div>
          <li> ◦ Transaction: sell <b className="gx-text-blue"> {forkForm.symbol} </b> / buy <b className="gx-text-blue"> {forkTo.symbol}</b> </li>
          <li> <BackTop /> {`◦ ${forkForm.symbol}`} outgoing (match 100%): <strong className="gx-text-blue"> {`${nu(6, amountForm)}`} </strong> <BackTop /></li>
          <li> <BackTop /> {`◦ ${forkTo.symbol}`} incoming (match 100%): <strong className="gx-text-blue"> {`${nu(6, amountForm * priceForm)}`} </strong> <BackTop /></li>
          <li> <BackTop /> ◦ Priority: <strong className="gx-text-blue"> {`${profit + '%'}`} </strong> <BackTop /></li>
          <li> <BackTop /> ◦ Price: <strong className="gx-text-blue"> {`1 ${forkForm.symbol} ~ ${nu(8, priceForm)} ${forkTo.symbol}`} </strong> <BackTop /></li>
          <li> <BackTop /> ◦ Min Amount accept: <strong className="gx-text-blue"> {`${minAmountOk * 100 + '%'}`} </strong> <BackTop /></li>
          <li> <BackTop /> ◦ Timeout: <strong className="gx-text-blue"> {`${timeSelect + ' hour'}`} </strong> <BackTop /></li>
          <hr />
          <li> <span> 🗣 After pressing 'OK', the transaction will be automatically matched when the system finds a matching reciprocal transaction, and the transaction will be deleted if the timeout expires. During the waiting time, the system automatically locks the transaction amount.</span></li>
        </div>
        // </ul>
      ),
      style: { width: 600 },
      onOk() {
        return new Promise((resolve, reject) => {
          orderTrade({
            forkId: forkForm.forkId,
            amount: Number.parseFloat(amountForm),
            minAmount: minAmountOk,
            forkSwapId: forkTo.forkId,
            priceSwap: Number.parseFloat(priceForm),
            priority: profit / 100,
            timeOut: timeSelect * 3600
          }, (data) => {
            NotificationManager.success('Your transaction order has been successful', 'Warning');
            dispatch(getWalletForkInfoStart({ forkId: forkForm.forkId }));
            dispatch(getMarketsStart())
            if (data.tradeInfo) {
              showMatchOrder({
                accName: data.tradeInfo.accName,
                priceMatch: data.tradeInfo.priceMatch,
                fee: data.tradeInfo.feeMatch,
                amountOutMatch: data.tradeInfo.amountOutMatch,
                amountInMatch: data.tradeInfo.amountInMatch,
              })
            }
            resolve()
          }, reject)
        })
      },
      onCancel() {
      },
    });
  }

  const showMatchOrder = (matchOrderInfo) => {
    Modal.success({
      title: 'The transaction is automatically matched',
      content: (
        <div>
          <li> <BackTop /> ◦ Trader: <strong className="gx-text-blue"> {`${matchOrderInfo.accName}`} </strong> <BackTop /></li>
          {/* <li> <BackTop /> ◦ Trading pair: <strong className="gx-text-blue"> {`${forkForm.symbol}/${forkTo.symbol}`} </strong> <BackTop /></li> */}
          <li> ◦ Transaction: sell <b className="gx-text-blue"> {forkForm.symbol} </b> / buy <b className="gx-text-blue"> {forkTo.symbol}</b> </li>
          <li> <BackTop /> ◦ Auto-matched Price: <strong className="gx-text-blue"> {`${nu(8, matchOrderInfo.priceMatch)}`} </strong> <BackTop /></li>
          <li> <BackTop /> {`◦ Amount (${forkForm.symbol}) Out:`} <strong className="gx-text-blue"> {`${nu(6, matchOrderInfo.amountOutMatch)}`} </strong> <BackTop /></li>
          <li> <BackTop /> {`◦ Amount (${forkTo.symbol}) In:`} <strong className="gx-text-blue"> {`${nu(6, matchOrderInfo.amountInMatch)}`} </strong> <BackTop /></li>
          <li> <BackTop /> ◦ Fee: <strong className="gx-text-blue"> {`${nu(8, matchOrderInfo.fee)}`} </strong> {`${forkForm.symbol}`} <BackTop /></li>
        </div>
      ),
      onOk() {
        // console.log('OK');
      },
      onCancel() {
        // console.log('Cancel');
      },
    });
  }
  
  return (
    <Col flex='560px'>
      <Widget title={<strong style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}> CREATE ORDER </strong>}>
        {/* <Tabs type="card" size={"large"} >
                    <TabPane tab="Create" key="1"> */}
        <div style={{ marginLeft: 10 }}>
          <Space direction="vertical">
            <Row gutter={10}>
              <Col>
                <li>Coin</li>
                <SelectCoin
                  value={{ value: forkForm ? forkForm.forkId : null }}
                  onChange={(el) => {
                    var ff = listFork.find(r => r.forkId === el.value)
                    setForkForm(ff)
                    setAmountForm('0.000000')
                    let ffPrice = ff.price
                    let ttPrice = forkTo ? forkTo.price : 0
                    let swapPrice = (ffPrice !== 0 && ttPrice !== 0) ? ffPrice / ttPrice : 0;
                    setPriceForm(swapPrice.toFixed(8));
                    setPriceTo((swapPrice === 0 ? 0 : 1 / (1 * swapPrice.toFixed(8))).toFixed(8));
                  }}
                  disableForkId={forkTo ? forkTo.forkId : undefined}
                />
              </Col>
              <Col>
                <li>Amount</li>
                <NumericInput onFocus={e => e.target.select()} value={amountForm} size="large" style={{ width: 140, fontSize: 17, fontWeight: 800 }}
                  onChange={value => {
                    setAmountForm(value)
                    priceForm && setAmountTo(nu(6, value * priceForm))
                  }}
                />
              </Col>
              <Col>
                <li>Min AMT</li>
                <Select size="large"
                  labelInValue
                  defaultValue={{ value: 0.6 }}
                  value={{ value: minAmountOk }}
                  onChange={el => { setMinAmountOk(el.value) }} style={{ width: 86, fontStyle: 'italic' }}>
                  {minAmountList.map(v => {
                    return (
                      <Select.Option size="large" style={{ fontStyle: 'italic' }}
                        key={v.value}
                        value={v.value}>
                        {v.str}
                      </Select.Option>
                    )
                  })}
                </Select>
              </Col>
              <Col>
                <li>Price</li>
                <NumericInput onFocus={e => e.target.select()} value={priceForm} size="large" style={{ width: 140, fontSize: 17, fontWeight: 800 }}
                  onChange={value => {
                    setPriceForm(value)
                    setPriceTo(nu(8, value * 1 === 0 ? 0 : 1 / value))
                    amountForm && setAmountTo(nu(6, value * amountForm))
                  }}
                />
                <li style={{ marginTop: 3, fontSize: 12, fontStyle: 'italic' }}>
                  {
                    forkForm && forkTo && (
                      !priceForm || priceForm === 0
                        ? `Sug: 1${forkForm.symbol} ~ ${nu(8, sugPrice, 7)}${forkTo.symbol}`
                        : `Sug: 1${forkForm.symbol} ~ ${nu(8, priceForm, 7)}${forkTo.symbol}`
                    )
                  }
                </li>
              </Col>
            </Row>
                            
            <Row gutter={10} style={{ paddingTop: 15, }} >
              <Col flex='98px'>
                <Arrow style={{ paddingBottom: 15, paddingLeft: 10 }}
                  onClick={() => {
                    var forkFromTmp = forkForm
                    var forkToTmp = forkTo
                    setForkForm(forkToTmp)
                    setForkTo(forkFromTmp)
                                            
                    var amountFromTmp = amountForm
                    var amountToTmp = amountTo
                    setAmountForm(amountToTmp)
                    setAmountTo(amountFromTmp)

                    var priceFormTmp = priceForm
                    var priceToTmp = priceTo
                    setPriceForm(priceToTmp)
                    setPriceTo(priceFormTmp)
                  }}
                />
              </Col>
                                
              <Col flex='auto'>
                <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', alignSelf: 'center' }}>
                  <Button type='primary' style={{ height: 48, borderRadius: 22, fontSize: 18, fontWeight: 700 }}
                    onClick={() => {
                      // console.log(minAmountOk, forkTo, profit, priceForm, timeSelect)
                      if (!forkForm || !amountForm || !minAmountOk
                        || !forkTo || !priceForm || !timeSelect) {
                        NotificationManager.warning('You need to enter all the fields', 'Warning');
                        return false
                      }
                      showConfirmOrder()
                    }}>
                    ORDER
                  </Button>
                </Row>
              </Col>
              <Col>
                <Row gutter={10} style={{ marginTop: 6, alignItems: 'center' }}>
                  <Col> <ClockCircleTwoTone twoToneColor="#84A459" style={{ fontSize: '22px' }} /> </Col>
                  <Col>
                    <Select size='middle'
                      labelInValue
                      defaultValue={{ value: 1 }} onChange={(el) => { setTimeSelect(el.value) }} style={{ width: 68, fontWeight: 700 }}>
                      {timeList.map(v => {
                        return (
                          <Select.Option size='middle' key={v.value} value={v.value}>
                            {v.str}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row gutter={10}>
              <Col>
                <li>Coin</li>
                <SelectCoin
                  value={{ value: forkTo ? forkTo.forkId : null }}
                  onChange={(el) => {
                    var tt = listFork.find(r => r.forkId === el.value)
                    setForkTo(tt)
                    setAmountTo('0.000000')
                    let ttPrice = tt.price
                    let ffPrice = forkForm ? forkForm.price : 0
                    let swapPrice = (ttPrice !== 0 && ffPrice !== 0) ? ttPrice / ffPrice : 0
                    setPriceTo(swapPrice.toFixed(8))
                    setPriceForm((swapPrice === 0 ? 0 : 1 / (1 * swapPrice.toFixed(8))).toFixed(8))
                  }}
                  disableForkId={forkForm ? forkForm.forkId : undefined}
                />
              </Col>
              <Col>
                <li>Amount</li>
                <NumericInput onFocus={e => e.target.select()} value={amountTo} size="large" style={{ width: 140, fontSize: 17, fontWeight: 800 }}
                  onChange={value => {
                    setAmountTo(value)
                    priceTo && setAmountForm(nu(6, value * priceTo))
                  }}
                />
              </Col>
              <Col>
                <li>Min AMT</li>
                <Select
                  size="large"
                  labelInValue
                  defaultValue={{ value: 0.6 }}
                  value={{ value: minAmountOk }}
                  onChange={(el) => { setMinAmountOk(el.value) }} style={{ width: 86, fontStyle: 'italic' }}>
                  {minAmountList.map(v => {
                    return (
                      <Select.Option size="large" style={{ fontStyle: 'italic' }}
                        key={v.value}
                        value={v.value}>
                        {v.str}
                      </Select.Option>
                    )
                  })}
                </Select>
              </Col>
              <Col>
                <li>Price</li>
                <NumericInput onFocus={e => e.target.select()} value={priceTo} size="large" style={{ width: 140, fontSize: 17, fontWeight: 800 }}
                  onChange={value => {
                    setPriceTo(value)
                    setPriceForm(nu(8, value * 1 === 0 ? 0 : 1 / value))
                    amountTo && setAmountForm(nu(8, value * amountTo))
                  }}
                />
                <li style={{ marginTop: 3, fontSize: 12, fontStyle: 'italic' }}>
                  {
                    forkForm && forkTo && (
                      !priceForm || priceForm === 0
                        ? `Sug: 1${forkTo.symbol} ~ ${nu(8, 1 / sugPrice, 7)}${forkForm.symbol}`
                        : `Sug: 1${forkTo.symbol} ~ ${nu(8, priceTo, 7)}${forkForm.symbol}`
                    )
                  }
                </li>
              </Col>
            </Row>
          </Space>
          <hr></hr>

          <li> <BackTop /> Auto-matched amount:
            <strong className="gx-text-blue"> {`${minAmountOk ? nu(8, amountForm * minAmountOk) : nu(8, amountForm)} - ${nu(8, amountForm)}`} </strong>
          </li>
          
          <li style={{ marginTop: 3 }}> <BackTop /> Auto-matched price:
            <strong className="gx-text-blue"> {`${nu(8, priceForm * (100 - profit) / 100)} - ${priceForm ? nu(8, priceForm) : '0.00000000'}`} </strong>
          </li>
          <li style={{ marginTop: 3 }}> <BackTop /> Transactions will be matched automatically and faster if you use priority:
            <strong className="gx-text-blue"> {`${profit}%`} </strong>
          </li>
          <p style={{ fontStyle: 'italic', marginTop: 9, color: '#84A459' }}><b> 👍 ◦ Fee 1%  applies to auto-matching transactions</b></p>
          <Row gutter={10} style={{ marginTop: 3 }}>
            <Col flex='60px' style={{ marginTop: 7 }}>
              <li>Priority: </li>
            </Col>
            <Col flex='auto' style={{ paddingLeft: 15, paddingRight: 15 }}>
              <Slider
                tipFormatter={value => `${value}%`}
                marks={marks}
                step={0.01}
                min={0} max={10}
                value={profit}
                onChange={(value) => { setProfit(value) }}
              />
            </Col>
          </Row>
        </div>
      </Widget>
    </Col>
  );
};
export default CreateOrder;