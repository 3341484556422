import React, { useState, useEffect } from "react";
import { Button, Select, Tabs, Input, notification, Space, Switch, Card } from "antd";
import { useSelector, useDispatch } from "react-redux";
import CircularProgress from "../../../components/CircularProgress";
import { NotificationManager} from "react-notifications";
import TelegramLoginButton from "react-telegram-login";

import Page from "../../Page";
import { profileFetchStart } from "../../../appRedux/actions";

import { on2FA,verify2FA,loginTelegram,accRename} from "../../../appRedux/sagas/Api";
import Avatar from "antd/lib/avatar/avatar";
import Lottie from "react-lottie"
import * as iconAnim from '../../forkP2p/icons/jsonIcons';

import Text from "antd/lib/typography/Text";
import Modal from "antd/lib/modal/Modal";
import QRCode from "react-qr-code";
import {
  FacebookShareCount,
  RedditShareCount,
  FacebookShareButton,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  TwitterShareButton,
  TelegramShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappShareButton,
  TelegramIcon,
  WhatsappIcon,
 
} from "react-share";
// import lodash from "lodash"
const { TabPane } = Tabs;

const Profile = ({ onDone }) => {
  // const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const authUser = useSelector(({ auth }) => auth.authUser);
  const [showMFA, setshowMFA] = useState(false)
  const [qrcode, setQrcode] = useState(null)
  const [otpCode, setOtpCode] = useState(null)
  const { profile } = useSelector(({ api }) => api);
  const [shareUrl, setshareUrl] = useState("")
  const [loader, setloader] = useState(false)
  // const [selectedIndex, setselectedIndex] = useState(0)
  const domain = 'https://chianow.net/';
  const title = 'Chianow - world of seeds';
  const [showEdit, setShowEdit] = useState(false);
  const [reName, setReName] = useState(profile ? profile.accName : '');
  useEffect(() => {
    if (profile !== null) {
      setshareUrl(`${domain}?refcode=${profile.refCode}`)
      setReName(profile.accName)
    }

  }, [profile]);
  
  useEffect(() => {
    if (authUser !== null) {
      dispatch(profileFetchStart())
    }

  }, []);
  const styleLabel = { fontWeight: 900, fontSize: 16 }
  const styleItem = { display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingLeft: 24, paddingRight: 24, paddingBottom: 12, paddingTop: 20 }
  const itemInfo = (label, info) => {
    return (
      <div style={styleItem}>
        <Text style={{ fontWeight: 900, fontSize: 16 }}>{label}</Text>
        <Text style={{ fontWeight: 600, fontSize: 16 }}>{(info == null || info === undefined) ? "N/A" : info}</Text>
      </div>
    )
  }
  return (
    <Page requireLogin={true}>
      {(profile !== undefined && profile !== null) ?
        <div>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingBottom: 24 }}>

            <Space>
              <Avatar src={profile !== null ? profile.accAvataURL : ""}
                alt="" />
              {/* <b>{profile.accName} </b> */}
              <Input
                onFocus={e => e.target.select()}
                size='small'
                value={reName}
                // bordered={false} 
                onChange={(e) => {
                  setShowEdit(true)
                  setReName(e.target.value)
                }}
                style={{width:105, fontWeight:'bold'}}
              />
              {showEdit && <Button type='primary' size='small' style={{ margin: 0 }}
                onClick={() => {
                  setShowEdit(false)
                  accRename({ newName : reName }, () => {
                    dispatch(profileFetchStart())
                  })
                }}  
              ><b> Edit </b> </Button>}
            </Space>
            <div style={{ marginLeft:5, color: "#84A459", fontWeight: "bold" }}>{"NOW ID: " + (profile.accID === null ? "N/A" : profile.accID)}</div>
          </div>

          <div>
            <Tabs onChange={() => { }} defaultActiveKey="1" size={"large"} style={{ marginBottom: 32 }} type="card">
              <TabPane tab="Info" key="1" >
                {
                  <div style={{
                    minHeight: 300,
                    display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'left', border: 20
                  }}>
                    {itemInfo("Email", profile.accMail)}
                    {itemInfo("Mobile", profile.accMobile)}
                    <div style={styleItem}>
                      <Text style={{ fontWeight: 900, fontSize: 16 }}>Love-Point</Text>
                      {(profile.lovePoint == null || profile.lovePoint === undefined) ? "N/A" :
                        
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                          <li style={{ fontSize: 20 }}><b>{profile.lovePoint}</b></li>
                          <div>
                            <Lottie width={52} height={52} speed={1} direction={1}
                              options={{
                                loop: true,
                                autoplay: true,
                                animationData: iconAnim.heart,
                                preserveAspectRatio: 'xMidYMid slice'
                              }} />
                          </div>
                        </div>
                      }
                    </div>

                    <div style={styleItem}>
                      <Text style={{ fontWeight: 900, fontSize: 16 }}>Now-Point</Text>
                      {(profile.nowPoint == null || profile.lovePoint === undefined) ? "N/A" :
                        
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                          <li style={{ fontSize: 20 }}><b>{profile.nowPoint}</b></li>
                          <div>
                            <Lottie width={52} height={52} speed={1} direction={1}
                              options={{
                                loop: true,
                                autoplay: true,
                                animationData: iconAnim.unname,
                                preserveAspectRatio: 'xMidYMid slice'
                              }} />
                          </div>
                        </div>
                      }
                    </div>

                    {/* <p>{"Email: " + profile.accMail}</p>
                    <p>{"Mobile: " + (profile.accMobile === null ? "N/A" : profile.accMobile)}</p> */}
                  </div>
                }
              </TabPane>
              <TabPane tab="Security" key="4" >
                <div style={{
                  minHeight: 300, 
                }}>
                  {<div style={styleItem}>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                      <Text style={styleLabel}> Two-factor authentication (using app)</Text>
                      {profile.enable2FA && <p style={{ fontSize: 12, marginTop: 3, marginRight: 15 }}>2FA verified, you can use <b>Google Authenticator</b> or <b>Authy</b> to get OTP code for Chianow transactions</p>}
                    </div>
                    <Switch
                      checked={profile.enable2FA}
                      onChange={value => {
                        if (value) {
                          on2FA((qr => {
                            setshowMFA(true)
                            setQrcode(qr)
                          }))
                        }
                      }}
                    ></Switch>
                  </div>}

                  <hr></hr>

                  {!profile.enable2FA && showMFA && <div>
                    <div style={{ paddingRight: 24, paddingLeft: 24, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                      <p>Scan the image below with the two-factor authentication app on your phone.</p>
                      <img src={qrcode} alt='' />
                      <p />
                      <p>Enter the six-digit code from the application</p>
                      <p>After scanning the QR code image, the app will display a six-digit code that you can enter below.</p>
                      <div
                        style={{ display: 'flex', flexDirection: 'row', alignSelf: 'left' }
                        }>
                        <Input style={{ width: 130, marginRight: 12, fontWeight: 700, fontSize: 16 }} type="number"
                          value={otpCode}
                          onChange={e => { setOtpCode(e.target.value) }}
                        />
                        <Button type="primary" style={{ margin: 0, fontWeight: 'bold' }}
                          onClick={() => {
                            if (!otpCode) {
                              NotificationManager.warning('You need to enter full six-digit', 'Warning');
                            } else {
                              verify2FA({ otpCode: otpCode }, () => {
                                setshowMFA(false)
                                dispatch(profileFetchStart())
                              })
                            }
                          }}
                        >VERIFY</Button>
                      </div>
                    </div>
                  </div>}

                  {<div style={styleItem}>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                      <Text style={styleLabel}> Link Telegram App </Text>
                      {/* <p style={{fontSize:12,marginTop:3,marginRight:15}}>2FA verified, you can use <b>Google Authenticator</b> or <b>Authy</b> to get OTP code for Chianow transactions</p> */}
                    </div>
                    {/* <Switch
                    checked={false}
                    onClick={() => {
                    }}
                  ></Switch> */}
                    <TelegramLoginButton
                      dataOnauth={(data) => {
                        loginTelegram({
                          id: data.id,
                          username: data.username,
                          photo_url: data.photo_url,
                          auth_date: data.auth_date
                        },()=>{},()=>{})
                      }} botName="chianowbot"
                    />
                  </div>}

                  <hr></hr>

                  {<div style={styleItem}>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                      <Text style={styleLabel}> Link Discord App </Text>
                      {/* <p style={{fontSize:12,marginTop:3,marginRight:15}}>2FA verified, you can use <b>Google Authenticator</b> or <b>Authy</b> to get OTP code for Chianow transactions</p> */}
                    </div>
                    <Switch
                      checked={false}
                      onClick={() => {
                      }}
                    ></Switch>
                  </div>}
                </div>
              </TabPane>
              {/* <TabPane tab="Affiliate" key="2" >
                <div style={{
                  display: 'flex', flexWrap: 'wrap', flexDirection: 'column', justifyContent: 'space-around',
                  minHeight: 300, alignItems: 'center'
                }}>
                  <span style={styleLabel}>Share this link to get reward</span>
                  <Input
                    addonAfter={
                      <li className='cursor-pointer'
                        onClick={() => { navigator.clipboard.writeText(shareUrl) }}>Copy</li>}
                    addonBefore="Invite URL"
                    contentEditable={false} value={shareUrl} />

                  <Space>
                    <FacebookShareButton
                      url={shareUrl}
                      quote={title}
                    className="Demo__some-network__share-button"
                    >
                      <FacebookIcon size={32} round />
                    </FacebookShareButton>
                    <TwitterShareButton
                      url={shareUrl}
                      title={title}
                      className="Demo__some-network__share-button"
                    >
                      <TwitterIcon size={32} round />
                    </TwitterShareButton>
                    <TelegramShareButton
                      url={shareUrl}
                      title={title}
                      className="Demo__some-network__share-button"
                    >
                      <TelegramIcon size={32} round />
                    </TelegramShareButton>
                    <WhatsappShareButton
                      url={shareUrl}
                      title={title}
                      separator=":: "
                      className="Demo__some-network__share-button"
                    >
                      <WhatsappIcon size={32} round />
                    </WhatsappShareButton>
                  </Space>

                </div>
              </TabPane> */}
              
              <TabPane tab="VIP" key="3" >
                <div style={{
                  display: 'flex', flexWrap: 'wrap', flexDirection: 'column', justifyContent: 'space-around',
                  minHeight: 300, alignItems: 'center'
                }}>
                  <strong>{(profile.accType === "VIP" ? "VIP" : "Your account is not VIP")}</strong>
                </div>
              </TabPane>
            </Tabs>
          </div>
        </div>
        :
        (<CircularProgress className="gx-loader-400" />)
      }
    </Page >
  );
}

export default Profile;
