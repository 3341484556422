import React, { useEffect } from "react";
import { Layout,Modal,Button ,Space} from "antd";
import { useDispatch, useSelector } from "react-redux";
import {NotificationContainer, NotificationManager} from "react-notifications";
import Sidebar from "../Sidebar/index";
import HorizontalDefault from "../Topbar/HorizontalDefault/index";
import HorizontalDark from "../Topbar/HorizontalDark/index";
import InsideHeader from "../Topbar/InsideHeader/index";
import AboveHeader from "../Topbar/AboveHeader/index";

import BelowHeader from "../Topbar/BelowHeader/index";
import Topbar from "../Topbar/index";
import { footerText } from "../../util/config";
import App from "../../routes/index";
import Lottie from "react-lottie"
import * as iconAnim from '../../routes/forkP2p/icons/jsonIcons';

// import Customizer from "../Customizer";
import {
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  TAB_SIZE,
} from "../../constants/ThemeSetting";
import NoHeaderNotification from "../Topbar/NoHeaderNotification/index";
import { useRouteMatch } from "react-router-dom";
import { updateWindowWidth } from "../../appRedux/actions";
import { offNoti } from "../../appRedux/actions/Api";

const { Content, Footer } = Layout;

const getContainerClass = (navStyle) => {
  switch (navStyle) {
    case NAV_STYLE_DARK_HORIZONTAL:
      return "gx-container-wrap";
    case NAV_STYLE_DEFAULT_HORIZONTAL:
      return "gx-container-wrap";
    case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
      return "gx-container-wrap";
    case NAV_STYLE_BELOW_HEADER:
      return "gx-container-wrap";
    case NAV_STYLE_ABOVE_HEADER:
      return "gx-container-wrap";
    default:
      return "";
  }
};

const getNavStyles = (navStyle) => {
  switch (navStyle) {
    case NAV_STYLE_DEFAULT_HORIZONTAL:
      return <HorizontalDefault />;
    case NAV_STYLE_DARK_HORIZONTAL:
      return <HorizontalDark />;
    case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
      return <InsideHeader />;
    case NAV_STYLE_ABOVE_HEADER:
      return <AboveHeader />;
    case NAV_STYLE_BELOW_HEADER:
      return <BelowHeader />;
    case NAV_STYLE_FIXED:
      return <Topbar />;
    case NAV_STYLE_DRAWER:
      return <Topbar />;
    case NAV_STYLE_MINI_SIDEBAR:
      return <Topbar />;
    case NAV_STYLE_NO_HEADER_MINI_SIDEBAR:
      return <NoHeaderNotification />;
    case NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR:
      return <NoHeaderNotification />;
    default:
      return null;
  }
};

const getSidebar = (navStyle, width) => {
  if (width < TAB_SIZE) {
    return <Sidebar />;
  }
  switch (navStyle) {
    case NAV_STYLE_FIXED:
      return <Sidebar />;
    case NAV_STYLE_DRAWER:
      return <Sidebar />;
    case NAV_STYLE_MINI_SIDEBAR:
      return <Sidebar />;
    case NAV_STYLE_NO_HEADER_MINI_SIDEBAR:
      return <Sidebar />;
    case NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR:
      return <Sidebar />;
    default:
      return null;
  }
};

const MainApp = () => {
  const { navStyle } = useSelector(({ settings }) => settings);
  const { notiData, profile } = useSelector(({ api }) => api);
  const { width } = useSelector(({ common }) => common);
  const match = useRouteMatch();
  const dispatch = useDispatch();

  useEffect(() => {
    window.addEventListener("resize", () => {
      // console.log(window.innerWidth)
      dispatch(updateWindowWidth(window.innerWidth));
    });
  }, [dispatch]);

  return (
    width >= 1080 ?
      <Layout className="gx-app-layout">
        {getSidebar(navStyle, width)}
        <Layout>
          {getNavStyles(navStyle)}
          <Content className={`gx-layout-content ${getContainerClass(navStyle)} `}>
            <App match={match} />
            <Footer>
              <div className="gx-layout-footer-content">{footerText}</div>
            </Footer>
            <Modal
              title={<strong style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>Wellcome new day</strong>}
              visible={!(notiData===null)} width={500} closable={false}
              footer={
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <Button key="thank" type='primary' onClick={() => { dispatch(offNoti()) }}>
                    <b>Thank!</b>
                  </Button>
                </div>
              }
              destroyOnClose maskClosable={false}
            >
              <Space>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                  <li style={{ fontSize: 16 }}> First login of the day, we give you : </li>
                  <p/>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    {profile && <li style={{ fontSize: 24 }}><b>{` ◦ ${profile.lovePoint}`}</b></li>}
                    <div>
                      <Lottie width={52} height={52} speed={1} direction={1}
                        options={{
                          loop: true,
                          autoplay: true,
                          animationData: iconAnim.heart,
                          preserveAspectRatio: 'xMidYMid slice'
                        }} />
                    </div>
                    <div>
                      <li style={{ fontSize: 16 }}><b> (LOVE Point)</b></li>
                      <li style={{ fontSize: 13 }}>LOVE your favorite coins every day and get airdrop reward</li>
                    </div>
                  </div>

                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <li style={{ fontSize: 24 }}><b> ◦ 10 </b></li>
                    <div>
                      <Lottie width={52} height={52} speed={1} direction={1}
                        options={{
                          loop: true,
                          autoplay: true,
                          animationData: iconAnim.unname,
                          preserveAspectRatio: 'xMidYMid slice'
                        }} />
                    </div>
                    <div>
                      <li style={{ fontSize: 16 }}><b> (NOW Point)</b></li>
                      <li style={{ fontSize: 13 }}>Accumulate to redeem our tokens in the future</li>
                    </div>
                  </div>
                  
                </div>
              </Space>
            </Modal>
          </Content>
        </Layout>
        {/* <Customizer/> */}
        <NotificationContainer />
      </Layout >
      :
      <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', padding: 100, textAlign: 'center' }}>
        <img alt="example" style={{ maxWidth: 180, height: "auto", display: "block", marginLeft: "auto", marginRight: "auto" }} src="https://res.cloudinary.com/chianow-jsc/image/upload/v1630491782/chianow-logo-text.png" />
        <h1> </h1>
        <h1><strong> We do not support this screen size, please use on larger device screen. We optimize the user experience and will release a version for smaller screens</strong></h1>
        <h1> </h1>
        <h1> </h1>
        <h1> </h1>
        <h1 style={{ color: 'darkcyan' }}><strong> COMING SOON . . .</strong></h1>
      </div >
      
  );
};
export default MainApp;
