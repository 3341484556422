import React, { useEffect, useState } from "react";
// import { useDispatch } from "react-redux";
import { Avatar, Popover,Space } from "antd";
import { userSignOut } from "appRedux/actions/Auth";
import { profileFetchStart } from "appRedux/actions/Api";
import { useSelector, useDispatch } from "react-redux";
// import { Link, useHistory } from "react-router-dom";
import Modal from "antd/lib/modal/Modal";
import Profile from "./Profile";


const UserInfo = () => {
  const dispatch = useDispatch();
  const profile = useSelector(({ api }) => api.profile);
  // const authUser = useSelector(({ auth }) => auth.authUser);
  // const history = useHistory();
  const [visible, setVisible] = useState(false)
  const [showProfile, setshowProfile] = useState(false)
  useEffect(() => {
    // console.log("useEffect", profile)
    dispatch(profileFetchStart())
  }, []);
  const handleVisibleChange = () => {
    setVisible(!visible)
  };
  const userMenuOptions = () => {
    if (profile === null) {
      // return (<ul className="gx-user-popover">
      //   <li onClick={() => history.push("/signin")}>Login
      //   </li>
      // </ul >
      // );
    }
    else {
      return (
        <Space>
          <div className="gx-user-popover">
            <li
              style={{ paddingBottom: 12 }}
              onClick={() => {
                setshowProfile(true);
                setVisible(false)
              }}> <b>◦ My Profile</b></li>
            {/* <hr /> */}
            <li
              style={{ paddingBottom: 12 }}
              onClick={() => {
                setVisible(false)
                dispatch(userSignOut())
              }}> <b>◦ Logout</b></li>
            <Modal
              visible={showProfile}
              title={<strong style={{ display: 'flex', alignSelf: 'center', justifyContent: 'center' }}>PROFILE</strong>}
              footer={null}
              style={{ top: 50 }}
              onCancel={() => {
                setshowProfile(false)
              }}
              onOk={() => {
                setshowProfile(false)
              }}
            >
              <Profile
                onDone={() => {
                  setshowProfile(false)
                }}></Profile>
            </Modal>
          </div >
        </Space>
      );
    }
  }

  return (
    <>
      <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight"
        content={userMenuOptions()}
        onVisibleChange={handleVisibleChange}
        visible={visible}
        trigger="click">
        <Avatar src={profile !== null ? profile.accAvataURL : ""}
          className="gx-avatar gx-pointer" alt="" />
      </Popover>

    </>
  )

}

export default UserInfo;
