import React, { useState, useEffect } from "react";
import { Button, BackTop } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { startCounter } from "../../../appRedux/actions/Common";
import { converTime } from "../../../util/Tools"

const PriceItem = ({
  styleName,
  headerStyle,
  itemStyle,
}) => {
  const dispatch = useDispatch();
  const { secs } = useSelector(({ common }) => common);
  const { listEvent, eventForkInfo } = useSelector(({ api }) => api);

  var s = parseInt((Date.parse(listEvent.startTime) - Date.now()) / 1000)

  useEffect(() => {
    if (listEvent.startTime !== undefined) { dispatch(startCounter(s)) }
  }, [listEvent.startTime, secs])

  return (
    <div className={`${styleName}`}>
      <div style={{ alignSelf: 'center', marginTop: 30 }}>
        <strong style={{ fontSize: 20 }}>AIRDROP EVENT</strong>
      </div>
      <div className={`${headerStyle}`}>
        <p className="gx-letter-spacing-base gx-text-white gx-text-uppercase gx-mb-0"> Countdown </p>
        <h3 className="gx-price">{converTime(secs)}</h3>
      </div>
      <div className={`${itemStyle}`}>
        <li> <span>{`Each person has ${eventForkInfo.loveDairy} heart drops per day. Heart drop does not accumulate and will reset at 00:00-GMT.`}</span></li>
        <li> <BackTop /> Please drop heart <strong className="gx-text-blue"> {`${eventForkInfo.loveDairy}`} </strong> <BackTop /> coins that you like the most.</li>
        <li> <span>After completing the task, you need to choose one of the 3 fork coins for us to airdrop</span> </li>
        <li> <BackTop /> Total number of airdrops this time: <strong className="gx-text-blue"> {eventForkInfo.forks.map(r => ` ${r.qty} lượt ${r.symbol}, `)} </strong> </li>
        <li> <BackTop /> Amount per airdrop: <strong className="gx-text-blue"> {eventForkInfo.forks.map(r => ` ${r.prize} ${r.symbol} `)} </strong> </li>
        <li> <span>We connect with other fork admins and airdrop regularly.</span> </li>
        <li> <BackTop /> Note: Each account or each IP can only be received once within <strong className="gx-text-blue"> {`${eventForkInfo.wait} hours.`} </strong> </li>
        {/* <div style={{ alignSelf: 'center', marginTop: 20 }}> <Button size="large" type="primary" onClick={() => {}}> REWARD </Button> </div> */}
      </div>
    </div>
  )
};

export default PriceItem;

