import React from "react";
import { host } from "../../constants/ApiServer";
import { useSelector} from "react-redux";
import { Select } from "antd";

const SelectCoin = (props) => {
  const { value, onChange, disableForkId, all } = props
  const { listFork } = useSelector(({ api }) => api)
  return (
    <Select size="large"
      labelInValue
      value={value}
      onChange={onChange}
      style={{ width: 98 }}>
      {
        all && <Select.Option size="large" key={-1} value={0}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <strong>ALL</strong>
          </div>
        </Select.Option>
      }
      {
        listFork.map((item, index) => {
          if (!item.noWallet) {
            return (
              <Select.Option size="large" key={index} value={item.forkId}
                disabled={disableForkId && item.forkId === disableForkId}>
                <div>
                  <img className="gx-rounded-circle gx-size-15 gx-mr-2" src={host + item.picUrl} alt="" />
                  {item.symbol}
                </div>
              </Select.Option>
            );
          }
        })
      }
    </Select>
  )
}
export default SelectCoin;