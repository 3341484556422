import React, { useState, useEffect } from "react";
import { Button, Select, Tabs, Input, Modal,Table,Tooltip, Typography,Slider,BackTop } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { NotificationManager} from "react-notifications";
// import { getallwalletStart } from "appRedux/actions/Api";
import { getWalletForkInfoStart } from "appRedux/actions/Api";
import { getWalletHistory, withDraw, withDrawOTP,verifyReCapcha} from '../../appRedux/sagas/Api';
import { initAddress } from "appRedux/sagas/Api";
import CircularProgress from "../../components/CircularProgress";
import QRCode from "react-qr-code";
import Page from "../Page";
import Text from "antd/lib/typography/Text";
import { ReCaptcha } from 'react-recaptcha-v3'
import NumericInput from "../components/NumericInput"
import { host } from "../../constants/ApiServer";
import { unMojo,unix_to_short_date } from "../../util/Tools";

const { TabPane } = Tabs;
const { Option } = Select;

const maskMail = (email) => {
  var a = email.indexOf('@')
  var b = ''
  var e = ''
  for (let i = 0; i < a - 3; i++) { b += '*' }
  for (let i = 0; i < email.length - a - 2; i++) { e += '*' }
  return (email.substring(0, 3) + b + '@' + email[a + 1] + e)
}

const Walllet = () => {
  // const [loader, setLoader] = useState(false);
  const [loader, setloader] = useState(false)
  const dispatch = useDispatch();
  const authUser = useSelector(({ auth }) => auth.authUser);
  const { listFork, profile } = useSelector(({ api }) => api)
  const [seletedCoin, setseletedCoin] = useState(listFork[0])
  const [checkCapcha, setCheckCapcha] = useState(false);
  const [walletHistory, setWalletHistory] = useState([])
  const [amountWithdraw, setAmountWithdraw] = useState(0)
  const [slideValue, setSlideValue] = useState(0)
  const [addressWithdraw, setAddressWithdraw] = useState('')
  const [showWithdrawConfirm, setShowWithdrawConfirm] = useState(false)
  const [otpCode01, setOtpCode01] = useState(null)
  const [otpCode02, setOtpCode02] = useState(null)
  const [withdrawReqId, setWithdrawReqId] = useState(null)
  const [withdrawLoading, setWithdrawLoading] = useState(false)

  // { title: "Addr", width: 250,
  //     render: (item) => {
  //       return (
  //         <Tooltip placement="top" title={item.address}>
  //           <Typography.Text ellipsis={true} style={{ width: 250 }}>
  //             {item.address}
  //           </Typography.Text>
  //         </Tooltip>
  //     )},
  //   },
  const columnsHistory = [
    { title: "ID", width: 60, dataIndex: "txID" },
    {
      title: "Amount", width: 120, render: (item) => {
        return (
          <li>{`${unMojo(item.forkId, item.amount)}`}</li>
        )
      },
    },
    {
      title: "Fee", width: 120, render: (item) => {
        return (
          <li>{`${unMojo(item.forkId, item.fee)}`}</li>
        )
      },
    },
    {
      title: "Addr", width: 200, render: (item) => {
        return (
          <Tooltip placement="top" title={item.address}>
            <Typography.Text ellipsis={true} style={{ width: 200 }}>
              {item.address}
            </Typography.Text>
          </Tooltip>
        )
      },
    },
    { title: "Type", width: 110, dataIndex: "type", },
    {
      title: "Memo", width: 200, render: (item) => {
        return (
          <Tooltip placement="top" title={item.memo}>
            <Typography.Text ellipsis={true} style={{ width: 200 }}>
              {item.memo}
            </Typography.Text>
          </Tooltip>
        )
      },
    },
    {
      title: "Time", width: 150, render: (item) => {
        return (
          <li>{`${unix_to_short_date(new Date(item.timeStamp))}`}</li>
        )
      },
    },
  ];

  const marks = {
    0: (0).toFixed(5),
    50: unMojo(seletedCoin.forkId, ((seletedCoin.balance * 0.3) / 2)),
    100: unMojo(seletedCoin.forkId, seletedCoin.balance * 0.3)
  }
  
  useEffect(() => {
    if (authUser !== null) {
      if (listFork.length > 0) {
        getWalletFork(seletedCoin.forkId)
        getWalletHistory({ forkId: seletedCoin.forkId }, history => {
          setWalletHistory(history)
        })
        setAmountWithdraw('0.00000')
        setSlideValue(0)
      }
    }
  }, [listFork]);
  
  
  useEffect(() => {
    // setseletedCoin(true); // show save btn if there is changes in state
    // setseletedCoin(listFork.find(({ forkId }) => forkId === setseletedCoin.forkId))
    setloader(false)
  }, [seletedCoin]);
  
  
  function callback(key) {

  }
  
  
  function getWalletFork(value) {
    setloader(true);
    dispatch(getWalletForkInfoStart({ forkId: value }));
  }
  
  
  function handleSelectCoin(value) {
    const sec = listFork.find(({ forkId }) => forkId === value)
    setseletedCoin(sec)
    getWalletFork(value);
    getWalletHistory({ forkId: value }, history => {
      setWalletHistory(history)
    })
    // getWalletHistory({ forkId: value }, history => {
    //   setWalletHistory(history)
    // })
  }
  
  
  function initValue(item) {
    // console.log(item)
    return <div>
      <img
        className="gx-rounded-circle gx-size-15 gx-mr-2"
        src={host + item.picUrl}
        alt=""
      />{item.symbol}</div>
  }



  return (
    <Page requireLogin={true}>
       <ReCaptcha
        sitekey="6LdmkT0cAAAAAEW5B-MAFrofDAu_1kZTHfQM2-UK"
        action='GetAirdrop'
        verifyCallback={(capchaToken) => {
          verifyReCapcha({capchaToken:capchaToken},()=>{setCheckCapcha(true)})
        }}
      />

      {(listFork !== undefined && listFork.length > 0) ?
        <div>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingBottom: 24 }}>
            <div className="gx-flex-row gx-align-items-center">
              {/* initialValue ={{ value: listFork[0].symbol }} */}
              <Select defaultValue={initValue(listFork[0])} style={{ width: 100 }} onChange={handleSelectCoin}>
                {listFork.map((item, index) => {
                  if (!item.noWallet) {
                    return (
                      <Option key={index} value={item.forkId}>
                        {initValue(item)}
                      </Option>
                    );
                  }
                })}
              </Select>
            </div>
            {/* + seletedCoin.balance_total === undefined ? "" : seletedCoin.balance_total */}
            <h2 className="h4 gx-mb-3 gx-mb-sm-1 gx-mr-2">
              {"Balance: " + (seletedCoin.balance === undefined ? "N/A" : unMojo(seletedCoin.forkId, seletedCoin.balance) + seletedCoin.symbol)}
            </h2>
          </div>
          
          <div style={{
            minWidth: 470,
            // minHeight: 420
          }}>
            <Tabs onChange={callback} defaultActiveKey="1" size={"large"} style={{ marginBottom: 32 }} type="card">
              <TabPane tab="Deposit" key="1" >

                {
                  seletedCoin.address ?
                    <div style={{
                      minHeight: 420,
                      display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center', border: 20
                    }}>
                      <QRCode size={160} value={seletedCoin.address} />
                      <Input
                        addonAfter={<li className='cursor-pointer' onClick={() => { navigator.clipboard.writeText(seletedCoin.address) }}>Copy</li>}
                        addonBefore="ADR"
                        contentEditable={false} value={seletedCoin.address} />
                      <p style={{ color: '#84A459', fontStyle: 'italic' }}><b>{`Please wait a few minutes to receive ${seletedCoin.symbol} to this wallet address.`}</b></p>
                      <ul>
                        <li> <span>{`Minimum amount 0.001` + seletedCoin.symbol}</span></li>
                        <li> <span>Please use the COPY button and double check the address before sending coins.</span></li>
                        <li> <span>{`Only send ${seletedCoin.symbol} to this address, you may lose your deposit forever if you send these coins to another address`}</span></li>
                      </ul>

                    </div>
                    :
                    <div style={{
                      minHeight: 420,
                      display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center', border: 20
                    }}>
                      <Text style={{ width: 400, marginTop: 10, textAlign: 'center' }}>The wallet address has not been initialized, please initialize the address to deposit</Text>
                      <Button
                        type="primary"
                        style={{
                          borderRadius: 12,
                          height: 42,
                          marginBottom: 0,
                          fontSize: 18,
                          fontWeight: 700
                        }}
                        onClick={() => { initAddress({ forkId: seletedCoin.forkId }, () => { setTimeout(function () { getWalletFork(seletedCoin.forkId) }, 1000) }) }}
                      >NEW ADDRESS</Button>
                    </div>
                }
              </TabPane>

              <TabPane tab="Withdraw" key="2" >
                {
                  (seletedCoin.balance > 0 && seletedCoin.balance !== undefined) ?
                    <div style={{
                      display: 'flex', flexWrap: 'wrap', flexDirection: 'column', justifyContent: 'space-around',
                      minHeight: 420, alignItems: 'center'
                    }}>
                      {/* <Space direction="vertical"> */}
                      <Text style={{ marginTop: 10 }}>Enter the Wallet address:</Text>
                      <Input size='large' style={{ marginTop: 3 }} addonBefore="ADR" placeholder="enter address wallet"
                        value={addressWithdraw} onChange={e => { setAddressWithdraw(e.target.value) }} onFocus={e => e.target.select()}
                      />
                      <Text style={{ marginTop: 10 }}>{`Enter the amount (fee 0.1% is ${(amountWithdraw ? amountWithdraw * 0.001 : 0).toFixed(5)})`}</Text>
                      <NumericInput value={amountWithdraw} size="large" style={{ width: 180, fontSize: 17, fontWeight: 800, marginTop: 3 }}
                        onFocus={e => e.target.select()}
                        onChange={value => {
                          let min = 0
                          let max = unMojo(seletedCoin.forkId, seletedCoin.balance * 0.3) * 1
                          if (value !== '') {
                            setAmountWithdraw(value)
                            setSlideValue((100 * (value * 1 - min) / (max - min)).toFixed(0))
                          } else {
                            setAmountWithdraw('')
                            setSlideValue(0)
                          }
                        }}
                      />
                      <div style={{ marginLeft: 20, marginRight: 20, marginBottom: 5, width: 400 }}>
                        <Slider
                          tipFormatter={value => `${((seletedCoin.balance * 0.3) * value / 100).toFixed(0)} Mojo`}
                          marks={marks}
                          step={1}
                          min={0} max={100}
                          value={slideValue}
                          onChange={(value) => {
                            setSlideValue(value)
                            setAmountWithdraw(unMojo(seletedCoin.forkId, (seletedCoin.balance * 0.3) * value / 100))
                          }}
                        />
                      </div>

                      <p style={{ color: '#84A459', fontStyle: 'italic' }}><b>{`Please wait a few minutes to receive ${seletedCoin.symbol} to this wallet address.`}</b></p>

                      <Button style={{ borderRadius: 12, height: 42, marginBottom: 10, fontSize: 18, fontWeight: 700 }} type="primary" onClick={() => {
                        if (!profile.enable2FA) {
                          NotificationManager.warning('You must enable 2FA', 'Warning');
                        } else {
                          if (!amountWithdraw || !addressWithdraw) {
                            NotificationManager.warning('You need to enter all the fields', 'Warning');
                          } else {
                            withDrawOTP({ forkId: seletedCoin.forkId, amount: amountWithdraw, address: addressWithdraw }, (data) => {
                              // console.log(data.data.reqId)
                              setWithdrawReqId(data.data.reqId)
                              setShowWithdrawConfirm(true)
                            })
                          }
                        }
                      }}>WITHDRAW</Button>
                      <ul>
                        <li> <span>You must enable 2FA</span></li>
                        <li> <span>New account created less than 3 days, no withdrawal yet</span></li>
                        <li> <span>Daily withdrawal limit does not exceed 30%</span></li>
                        <li> <span>{`Only send ${seletedCoin.symbol} to this address, you may lose your deposit forever if you send these coins to another address`}</span></li>
                      </ul>
                    </div>
                    :
                    (seletedCoin.balance === undefined ?
                      <div style={{
                        display: 'flex', flexWrap: 'wrap', flexDirection: 'column', justifyContent: 'space-around',
                        minHeight: 420, alignItems: 'center'
                      }}>
                        <Text style={{ width: 400, marginTop: 10, textAlign: 'center' }}>The wallet address has not been initialized, please initialize the address to deposit</Text>
                        <Button
                          type="primary"
                          style={{ borderRadius: 12, height: 42, marginBottom: 0, fontSize: 18, fontWeight: 700 }}
                          onClick={() => { initAddress({ forkId: seletedCoin.forkId }, () => { setTimeout(function () { getWalletFork(seletedCoin.forkId) }, 1000) }) }}
                        >NEW ADDRESS</Button>
                      </div> :
                      <div style={{
                        display: 'flex', flexWrap: 'wrap', flexDirection: 'column', justifyContent: 'center',
                        minHeight: 420, alignItems: 'center'
                      }}>
                        <Text style={{ width: 400, marginTop: 10, textAlign: 'center' }}>Your wallet has no balance to withdraw</Text>
                      </div>
                    )
                }
              </TabPane>
              
              <TabPane tab="History" key="3" >
                {walletHistory.length > 0 ?
                  <div style={{
                    display: 'flex', flexWrap: 'wrap', flexDirection: 'column', justifyContent: 'flex-start',
                    minHeight: 420, alignItems: 'center'
                  }}>
                    <Table
                      className="gx-table-no-bordered"
                      columns={columnsHistory}
                      dataSource={walletHistory}
                      pagination={false}
                      bordered={false}
                      size="small"
                      scroll={{ y: 370 }}
                    />
                  </div> :

                  <div style={{
                    display: 'flex', flexWrap: 'wrap', flexDirection: 'column', justifyContent: 'center',
                    minHeight: 420, alignItems: 'center'
                  }}>
                    <Text style={{ width: 400, marginTop: 10, textAlign: 'center' }}>You are not have any transaction</Text>
                  </div>
                }
              </TabPane>
            </Tabs>
          </div>

        </div>
        :
        (<CircularProgress className="gx-loader-400" />)
      }

      <Modal title={<strong style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        Confirm WithDraw </strong>} visible={showWithdrawConfirm} width={520} closable={false}
        confirmLoading = {withdrawLoading}
        destroyOnClose={true}
        maskClosable={false}
        okText = "Confirm"
        onOk={() => {
          if (!checkCapcha) return false;
          setWithdrawLoading(false)
          withDraw({ reqId: withdrawReqId, otpCode1: otpCode01, otpCode2: otpCode02 }, () => {
            setOtpCode01(null)
            setOtpCode02(null)
            setWithdrawLoading(false)
            setShowWithdrawConfirm(false)
            dispatch(getWalletForkInfoStart({ forkId: seletedCoin.forkId }));
            NotificationManager.success('Withdrawal has been done, please wait a few minutes', 'Success');
          }, (error) => {
            NotificationManager.warning(error.message, 'Warning');
            setOtpCode01(null)
            setOtpCode02(null)
            setWithdrawLoading(false)
            setShowWithdrawConfirm(false)
          })
        }}
        onCancel={() => {
          setOtpCode01(null)
          setOtpCode02(null)
          setShowWithdrawConfirm(false)
        }}>
        <space>
          <li> <BackTop /> ◦ Amount withdraw: <strong className="gx-text-blue">{` ${amountWithdraw} ${seletedCoin.symbol}`}</strong> <BackTop /></li>
          <li> <BackTop /> ◦ Withdraw to Address: <strong className="gx-text-blue"> {addressWithdraw} </strong> <BackTop /></li>
          <hr />
          <li> ◦ Please enter the verification code in the mail  <b>{maskMail(profile.accMail)}</b> (Valid for <b>5 minutes</b>) :</li>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            
            <Input style={{ marginTop: 5, width: 130, marginRight: 12, fontWeight: 700, fontSize: 16 }} type="number"
              value={otpCode01}
              onChange={e => { setOtpCode01(e.target.value) }} />
          </div>
          <p></p>
          <li> ◦ Please enter the verification code in <b>Google Authenticator</b> or <b>Authy</b> : </li>
          <li></li>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Input style={{ marginTop: 5, width: 130, marginRight: 12, fontWeight: 700, fontSize: 16 }} type="number"
              value={otpCode02}
              onChange={e => { setOtpCode02(e.target.value) }} />
          </div>
          <p></p>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop:5}}>
            <li style={{color:'#83a459'}}> <b>This withdrawal is done when all verification codes are correct. </b></li>
          </div>
        </space>
      </Modal>
      
    </Page >
  );
}

export default Walllet;
